import React from "react";

const Card = ({
  children,
  bgImg = "",
  css,
  pos = "top-0 left-0",
  filter = false,
}) => {
  // const Img = require(bgImg);
  // const Img = bgImg && require(`../../assets/img/${bgImg}`);
  const Img = bgImg && bgImg;

  return (
    <div className={`rounded-2xl relative overflow-hidden shadow-lg ${css}`}>
      {filter && (
        <div className="absolute left-0 top-0 bottom-0 right-0 bg-black bg-opacity-70"></div>
      )}
      {Img && <img src={Img} alt="" className="w-full h-full object-cover" />}
      <div className={`absolute px-6 py-6 ${pos} w-full`}>{children}</div>
    </div>
  );
};

export default Card;
