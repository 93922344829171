import React, { useState, useEffect, useRef } from "react";
import commentService from "../../services/comment";
import episodesService from "../../services/episode";
import { useParams } from "react-router-dom";
import { episodeActions } from "../../store/episode/episode-slice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";

const AddCommentEpisode = () => {
  const [comment, setComment] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  const toast = useToast();
  const toastRef = useRef(null);

  const [msgToast, setMsgToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [titleToast, setTitleToast] = useState("");

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    commentService
      .addCommentEpisode(id, user.token, comment)
      .then((res) => {
        setComment("");
        episodesService
          .get(id)
          .then((res) => {
            setTypeToast("success");
            setTitleToast("Comments.");
            setMsgToast(`We\'ve add a comment for you.`);

            dispatch(episodeActions.replaceData(res.episode));
            dispatch(episodeActions.dataLoading());
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(false);
      })
      .then((err) => setLoading(false));
  };

  // show toast msg
  useEffect(() => {
    if (msgToast && titleToast && typeToast) {
      toast({
        title: titleToast,
        description: msgToast,
        status: typeToast,
        duration: 5000,
        isClosable: true,
      });
    }
  }, [msgToast, titleToast, typeToast]);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex md:block flex-col justify-end"
    >
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="w-full bg-white rounded-lg px-6 py-4 outline-none text-xs md:text-sm"
        placeholder="add a comment"
      ></textarea>
      <button
        disabled={loading}
        type="submit"
        className="font-medium capitalize md:text-lg bg-primary-200 text-white px-8 py-2 rounded-lg mt-2 mb-8"
      >
        {loading ? (
          <Spinner
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        ) : (
          "Add"
        )}
      </button>
    </form>
  );
};

export default AddCommentEpisode;
