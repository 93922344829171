import React from "react";
import Card from "./Card";
import CustomButton from "../form/CustomButton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const PodcastSection = () => {
  const data = useSelector((state) => state.setting.item);
  const dataLoading = useSelector((state) => state.setting.status);

  if (!dataLoading) return null;

  return (
    <div className="section">
      <h1 className="title-section">All Kool Health Episodes</h1>
      <p className="text-sm mt-4 text-center w-full md:w-2/3 m-auto">
        {data.overview.description}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-4 mt-10">
        <Card
          bgImg={require("../../assets/img/_VIC8714-243.jpg")}
          css={"h-[300px] md:h-[300px] shadow-lg group"}
          pos="bottom-0"
        >
          <div className="p-4 hidden group-hover:block bg-primary-50 text-white">
            <h3 className="font-bold capitalize text-xl mb-1 text-center md:text-left">
              All Kool Health Episodes
            </h3>
            <p className="text-sm text-center md:text-left">
              {data.overview.podcastDescription}
            </p>
          </div>
          <Link to={"./podcasts/archives"}>
            <CustomButton
              name={"show podcasts"}
              css={"px-4 py-2 mt-6 text-sm mx-auto md:mx-0"}
            />
          </Link>
        </Card>
        <Card
          bgImg={require("../../assets/img/_VIC8623-157.jpg")}
          css={"h-[300px] md:h-[300px] shadow-lg group"}
          pos="bottom-0"
        >
          <div className="relative">
            <div className="p-4 hidden group-hover:block bg-primary-50 text-white">
              <h3 className=" font-bold capitalize text-xl mb-1 text-center md:text-left">
                Kool Health Show Notes
              </h3>
              <p className="text-sm text-center md:text-left">
                {data.overview.notesDescription}
              </p>
            </div>
            <Link to={"./notes"}>
              <CustomButton
                name={"show notes"}
                css={"px-4 py-2 mt-6 text-sm mx-auto md:mx-0"}
              />
            </Link>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PodcastSection;
