import { Pie } from 'react-chartjs-2';

const PieChartTotal = ({ valEpisode, valArticle, valTopics, valGroups }) => {
    const data = {
        labels: ['total episodes', 'total articles', 'total topics', 'total groups'],
        datasets: [
            {
            label: '# counts',
            data: [valEpisode.totalEpisodes, valArticle.totalArticle, valTopics.length, valGroups.length],
            backgroundColor: [
                '#122f9750',
                '#4c66c250',
                '#1aa7ec50',
                '#4adede50',
            ],
            borderColor: [
                '#122f97',
                '#4c66c2',
                '#1aa7ec',
                '#4adede',
            ],
            borderWidth: 1,
            },
        ],
    };

    return (
        <Pie data={data} width={"30%"} options={{ maintainAspectRatio: false }} />
    )
}

export default PieChartTotal