import axios from 'axios';
import { json } from 'react-router-dom';

const url = process.env.REACT_APP_API_URL


const getStatNotes = () => {
    return axios
        .get(`${url}/notes/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const getStatUser = () => {
    return axios
        .get(`${url}/users/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const getStatGroups = () => {
    return axios
        .get(`${url}/groups/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const getStatArticles = () => {
    return axios
        .get(`${url}/articles/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const getStatCategories = () => {
    return axios
        .get(`${url}/categories/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const getStatEpisodes = () => {
    return axios
        .get(`${url}/episodes/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const getStatComments = () => {
    return axios
        .get(`${url}/comments/statistics`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const statService = {
    getStatNotes,
    getStatArticles,
    getStatComments,
    getStatEpisodes,
    getStatCategories,
    getStatGroups,
    getStatUser
};
export default statService