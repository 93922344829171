import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const getAll = (search, status) => {
  const querySearch = search ? `&search=${search}` : "";
  const queryStatus = status ? `&status=${status}` : "";

  return axios
    .get(`${url}/users?${querySearch}${queryStatus}`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const get = (id) =>
  axios
    .get(`${url}/users/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const profile = (token) =>
  axios
    .get(`${url}/users/profile`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const toggleUser = (id) =>
  axios
    .put(`${url}/users/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const update = (id) =>
  axios
    .patch(`${url}/users/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const updateProfilInfo = (token, data) =>
  axios
    .patch(`${url}/users`, data, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const updatePwd = (token, data) =>
  axios
    .patch(`${url}/users/change-password`, data, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const updateByAdmin = (id, username, fullname, email, password) =>
  axios
    .patch(`${url}/users/${id}`, {
      username: username,
      email: email,
      password: password,
      name: fullname,
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const deleteUser = (id) =>
  axios
    .delete(`${url}/users/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const userService = {
  getAll,
  get,
  profile,
  toggleUser,
  update,
  updateByAdmin,
  updateProfilInfo,
  updatePwd,
  deleteUser,
};
export default userService;
