import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const getAll = (
  limit = 100,
  search = "",
  startDate = "",
  endDate = "",
  status = ""
) => {
  const querySearch = search ? `&search=${search}` : "";
  const queryDateStart = startDate ? `&startDate=${startDate}` : "";
  const queryDateEnd = endDate ? `&endDate=${endDate}` : "";
  const queryStatus = status !== "" ? `&isPublished=${status}` : "";

  return axios
    .get(
      `${url}/bookme?limit=${limit}${querySearch}${queryDateStart}${queryDateEnd}${queryStatus}`
    )
    .then((response) => response.data)
    .catch((error) => json(error));
};

const get = (id) =>
  axios
    .get(`${url}/bookme/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const getLast = (token) =>
  axios
    .get(`${url}/bookme/last`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const bookMe = (token, phone) =>
  axios
    .post(
      `${url}/bookme`,
      {
        phone: phone,
      },
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => json(error));

const updateBookMe = (id, status) =>
  axios
    .patch(`${url}/bookme/${id}`, {
      status: status,
    })
    .then((res) => res.data)
    .catch((error) => json(error));

const bookMeService = {
  getAll,
  get,
  getLast,
  updateBookMe,
  bookMe,
};

export default bookMeService;
