import { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ClockIcon from "../../assets/icons/clock.svg";
import LoveIcon from "../../assets/icons/love.svg";
import LoveActifIcon from "../../assets/icons/love_actif.svg";
import articlesService from "../../services/articles";
import { useDispatch, useSelector } from "react-redux";

const ArticleCard = ({ item, grid = false }) => {
  const [love, setLove] = useState(false);

  const date = new Date(item.createdAt);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const pathUrl = window.location.pathname;

  const id = item._id;

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user)
      articlesService
        .checkArticleFavorite(id, user.token)
        .then((res) => {
          setLove(res.isFavorited);
        })
        .then((err) => console.log(err));
  }, [user]);

  const handleFavorite = () => {
    if (love)
      articlesService
        .deleteArticleFavorite(id, user.token)
        .then((res) => {
          setLove(false);
        })
        .then((err) => console.log(err));
    else
      articlesService
        .addArticleFavorite(id, user.token)
        .then((res) => {
          setLove(true);
        })
        .then((err) => console.log(err));
  };

  console.log("res:", item);

  return (
    <div className={`${(pathUrl.includes("admin") || !grid) && "mb-4"}`}>
      <div
        className={`h-full bg-white shadow-lg rounded-xl p-3 relative group ${
          !grid && "flex items-center"
        } ${pathUrl.includes("admin") && "border border-primary-50"}`}
      >
        {love ? (
          <div
            className={`absolute right-5 top-5 z-40 cursor-pointer ${
              grid &&
              " rounded-full w-8 h-8 flex items-center justify-center top-44"
            }`}
            onClick={handleFavorite}
          >
            <img src={LoveActifIcon} alt="" className={`w-5`} />
          </div>
        ) : (
          <div
            className={`absolute right-5 top-5 z-40 cursor-pointer ${
              grid &&
              "rounded-full w-8 h-8 flex items-center justify-center top-44"
            }`}
            onClick={handleFavorite}
          >
            <img src={LoveIcon} alt="" className={`w-5`} />
          </div>
        )}
        <div className={`relative h-fit ${!grid ? "w-1/4 mr-8" : "w-full"}`}>
          <img
            src={process.env.REACT_APP_FILES_URL + "/" + item.image.url}
            alt=""
            className="h-[150px] w-full object-cover rounded-lg"
          />
          {item.category && item.category.title !== undefined && (
            <Link to={`/topics/${item.category._id}`}>
              <p className=" text-xs font-semibold absolute left-3 bottom-3 bg-primary-100 text-white rounded-md px-3 py-1">
                {item.category.title}
              </p>
            </Link>
          )}
        </div>
        <div className={`${!grid ? "w-3/4" : "w-full"}`}>
          <div
            className={`flex justify-between items-center ${grid && "mt-2"}`}
          >
            <div>
              <Link to={`/articles/${item._id}`}>
                <h1 className="hover:underline text-md md:text-lg capitalize font-semibold truncate w-[250px] mt-1">
                  {"# " + item.articleNumber + " " + item.title}
                </h1>
              </Link>
              <div className="flex items-center mt-2">
                <img src={ClockIcon} alt="" className="w-[15px]" />
                <p className="text-xs ml-2 font-semibold text-">
                  {item.readTime} min read
                </p>
              </div>
              <p className="text-sm mt-2 ">{item.content}</p>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <div className="w-[35px] h-[35px] bg-gray-500 rounded-full p-1">
              {item.writerImage && (
                <img
                  src={
                    process.env.REACT_APP_FILES_URL + "/" + item.writerImage.url
                  }
                  alt="?"
                  className="w-full object-cover rounded-full"
                />
              )}
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{item.writerName}</p>
              <p className="text-xs font-light">{formattedDate}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
