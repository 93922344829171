import { configureStore } from "@reduxjs/toolkit";
import listEpisodeSlice from "./episode/list-episode-slice";
import episodeSlice from "./episode/episode-slice";
import audioSlice from "./episode/audio-slice";
import filterSlice from "./filter/filter-slice";
import listCategorySlice from "./category/list-category-slice";
import categorySlice from "./category/category-slice";
import listArticleSlice from "./article/list-article-slice";
import articleSlice from "./article/article-slice";
import listUserSlice from "./user/list-user-slice";
import userSlice from "./user/user-slice";
import listGroupSlice from "./category/list-group-slice";
import groupSlice from "./category/group-slice";
import settingSlice from "./setting/setting-slice";
import listBookMeSlice from "./user/list-bookme-slice";
import authSlice from "./auth/auth-slice";

const store = configureStore({
  reducer: {
    episode: episodeSlice.reducer,
    listEpisode: listEpisodeSlice.reducer,
    audio: audioSlice.reducer,

    listCategory: listCategorySlice.reducer,
    category: categorySlice.reducer,
    listGroup: listGroupSlice.reducer,
    group: groupSlice.reducer,

    listArticle: listArticleSlice.reducer,
    article: articleSlice.reducer,

    listBookMe: listBookMeSlice.reducer,
    listUser: listUserSlice.reducer,
    user: userSlice.reducer,

    filter: filterSlice.reducer,

    setting: settingSlice.reducer,

    auth: authSlice.reducer,
  },
});

export default store;
