import { useEffect, useRef, useState } from "react";
import InputCustom from "../../components/form/InputCustom";
import categoriesService from "../../services/categories";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import userService from "../../services/user";
import { userActions } from "../../store/user/user-slice";
import { Spinner } from "@chakra-ui/react";

const ProfileChange = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const toastRef = useRef(null);
  const toastUpdateRef = useRef(null);

  const [username, setUserName] = useState("");
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [currentpwd, setCurrentPwd] = useState("");

  const [msgToast, setMsgToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [titleToast, setTitleToast] = useState("");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // send request to add or edit
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (user.token)
      userService
        .updateProfilInfo(user.token, {
          name: fullname,
          username: username,
          email: email,
          password: currentpwd,
        })
        .then((res) => {
          dispatch(
            userActions.changeData({
              username: res.user.local.username,
              name: res.user.local.name,
              email: res.user.local.email,
            })
          );
          dispatch(userActions.dataLoading());

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    // categoriesService
    //     .updateGroup(id, title,desecription, topicSelected)
    //     .then(res => {
    //         setNewId(id)
    //         setTypeToast('success')
    //         setTitleToast('Topic updated.')
    //         setMsgToast(`We\'ve update topic for you.`);
    //     })
    //     .catch(err=> console.log(err))
  };

  const clearForm = (e) => {
    e.preventDefault();
    setFullName(userData.name);
    setUserName(userData.username);
    setEmail(userData.email);
    setCurrentPwd("");
  };

  // show toast msg
  useEffect(() => {
    if (msgToast && titleToast && typeToast) {
      toast({
        title: titleToast,
        description:
          msgToast + " We will redirect you after 3 seconds to detail page",
        status: typeToast,
        duration: 5000,
        isClosable: true,
      });
      // setTimeout(() => {
      //     navigate(`../groups/${newId}`)
      // }, 3000);
    }
  }, [msgToast, titleToast, typeToast]);

  // get categories and fill fileds depend the id
  const userData = useSelector((state) => state.user.item);
  const userDataLoading = useSelector((state) => state.user.status);
  useEffect(() => {
    if (userData) {
      setFullName(userData.name);
      setUserName(userData.username);
      setEmail(userData.email);
    }
  }, [userData]);

  if (!userDataLoading) return null;

  return (
    <div>
      <p ref={toastRef}></p>

      <h1 className="text-xl font-medium">Edit Personal Information</h1>

      <form action="">
        {/* general information  */}
        <div className="mt-10">
          <InputCustom
            title={"fullname"}
            type="text"
            placeholder={"enter fullname"}
            item={fullname ? fullname : userData.name}
            setItem={setFullName}
          />
        </div>

        <div className="mt-10">
          <InputCustom
            title={"UserName"}
            type="text"
            placeholder={"enter username"}
            item={username ? username : userData.username}
            setItem={setUserName}
          />
        </div>

        <div className="mt-10">
          <InputCustom
            title={"email"}
            type="text"
            placeholder={"enter email"}
            item={email ? email : userData.email}
            setItem={setEmail}
          />
        </div>
        <div className="mt-10">
          <InputCustom
            title={"current password"}
            type="password"
            placeholder={"enter your current password"}
            item={currentpwd}
            setItem={setCurrentPwd}
          />
        </div>

        {/* buttons actions  */}
        {loading ? (
          <div className="w-full flex items-center justify-center mt-6 px-4 py-[12px]">
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </div>
        ) : (
          <div className="flex items-center justify-between md:justify-end w-full">
            <div className="w-fit flex justify-end px-4 mt-6 mb-4">
              <Link to={"../topics"}>
                <button
                  onClick={clearForm}
                  className="rounded-md px-10 py-3 md:py-2 font-semibold text-primary-200 border border-primary-200 bg-white text-xs md:text-base"
                >
                  Cancel
                </button>
              </Link>
            </div>
            <div className="w-fit flex justify-end px-4 mt-6 mb-4">
              <button
                disabled={!currentpwd}
                className={`bg-primary-100 rounded-md px-10 py-3 md:py-2 font-semibold text-white bg-primary-200 text-xs md:text-base ${
                  !currentpwd && "!bg-gray-300"
                }`}
                onClick={(e) => handleSubmit(e)}
              >
                {"Save changes"}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ProfileChange;
