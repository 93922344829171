import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const getAll = (
  limit = 100,
  search = "",
  startDate = "",
  endDate = "",
  duration,
  topic,
  status = ""
) => {
  const querySearch = search ? `&search=${search}` : "";
  const queryDuration = duration ? `&duration=${duration}` : "";
  const queryTopic = topic ? `&category=${topic}` : "";
  const queryDateStart = startDate ? `&startDate=${startDate}` : "";
  const queryDateEnd = endDate ? `&endDate=${endDate}` : "";
  const queryStatus = status !== "" ? `&isPublished=${status}` : "";

  return axios
    .get(
      `${url}/articles?limit=${limit}${querySearch}${queryDuration}${queryTopic}${queryDateStart}${queryDateEnd}${queryStatus}`
    )
    .then((response) => response.data)
    .catch((error) => json(error));
};

const get = (id) =>
  axios
    .get(`${url}/articles/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const getLast = () =>
  axios
    .get(`${url}/articles/last`)
    .then((response) => response)
    .catch((error) => json(error));

const getSimilar = (id, limit = 6) =>
  axios
    .get(`${url}/articles/similair/${id}?limit=${limit}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const getTrending = (limit = 6) =>
  axios
    .get(`${url}/articles/trending?limit=${limit}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const addArticle = (
  number,
  title,
  topic,
  content,
  duration,
  writerName,
  img,
  imgsOne
) =>
  axios
    .post(`${url}/articles`, {
      articleNumber: number,
      title: title,
      content: content,
      image: img,
      readTime: duration,
      category: topic,
      writerName: writerName,
      writerImage: imgsOne,
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const updateArticle = (
  id,
  title,
  topic,
  content,
  duration,
  writerName,
  img,
  imgsOne
) =>
  axios
    .patch(`${url}/articles/${id}`, {
      title: title,
      content: content,
      image: img,
      readTime: duration,
      category: topic,
      writerName: writerName,
      writerImage: imgsOne,
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const addArticleFavorite = (id, token) => {
  return axios
    .put(`${url}/articles/favorites/${id}`, null, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const checkArticleFavorite = (id, token) => {
  return axios
    .get(`${url}/articles/${id}/check-favorite`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const deleteArticleFavorite = (id, token) => {
  return axios
    .delete(`${url}/articles/favorites/${id}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));
};

const deleteArticle = (id) =>
  axios
    .delete(`${url}/articles/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const toggleArticle = (id) =>
  axios
    .put(`${url}/articles/isPublished/${id}`)
    .then((res) => res.data)
    .catch((error) => json(error));

const articlesService = {
  getAll,
  get,
  getLast,
  getSimilar,
  getTrending,
  addArticle,
  updateArticle,
  deleteArticle,
  toggleArticle,

  addArticleFavorite,
  checkArticleFavorite,
  deleteArticleFavorite,
};
export default articlesService;
