import { createSlice } from '@reduxjs/toolkit';

const listArticleSlice = createSlice({
    name: 'listArticle',
    initialState: {
        itemsList: [],
        status: null
    },
    reducers: {
        replaceData(state, action) {
            state.itemsList = action.payload;
        },
        dataLoading(state, action) {
            state.status = 'loading';
        },
        removeData(state, action) {
            state.itemsList = state.itemsList.filter(item => item._id !== action.payload)
        },
        removeDataComment(state, action) {
            state.itemsList = state.itemsList.map(item =>
                item._id === action.payload.id
                    ? {
                        ...item,
                        comments: item.comments.filter(comment => 
                            comment._id !== action.payload.idComment
                        )
                    }
                    : item
            )
        },
        toggleStatus(state, action) {
            state.itemsList = state.itemsList
                .map(item =>
                    item._id === action.payload ? { ...item, isPublished: !item.isPublished } : item);
        },
        clearAll(state, action) {
            state.itemsList = []
            state.status = null;
        },
    },
});

export const listArticleActions = listArticleSlice.actions;

export default listArticleSlice;
