import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const BarChartHorizontal = ({labelNames, val1, val2, val3}) => {
    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
            borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
        },
    };

    const labels = labelNames;

    const data = {
        labels,
        datasets: [
            {
            label: 'total read/play',
            data: val1,
            borderColor: '#122f97',
            backgroundColor: '#122f9750',
            },
            {
            label: 'published',
            data: val2,
            borderColor: '#1aa7ec',
            backgroundColor: '#1aa7ec50',
            },
            {
            label: 'not published',
            data: val3,
            borderColor: '#4adede',
            backgroundColor: '#4adede50',
            },
        ],
    };

    return <Bar options={options} data={data} />;
}

export default BarChartHorizontal
