import React, { useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import bookMeService from "../../services/bookme";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@chakra-ui/react";

const BookMe = () => {
  const [loading, setLoading] = useState(false);
  const [book, isBook] = useState(false);
  const [phone, setPhone] = useState("");

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user)
      bookMeService
        .getLast(user.token)
        .then((res) => {
          if (res && res[0]) {
            setPhone(res[0].phone);
            isBook(true);
          }
        })
        .catch((err) => {
          console.log(err);
          isBook(false);
        });
  }, [user]);

  const onSubmit = () => {
    if (user) {
      setLoading(true);
      bookMeService
        .bookMe(user.token, phone)
        .then((res) => {
          isBook(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="relative">
        {!book ? (
          <div>
            <input
              type="tel"
              name=""
              id=""
              placeholder="write your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="rounded-full py-2 pl-4 pr-36 text-gray-800 outline-none"
            />
            <div className="absolute right-0 top-0">
              {loading ? (
                <div className="px-4 mt-2">
                  <Spinner
                    thickness="2px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                  />
                </div>
              ) : (
                <CustomButton
                  name={"book me"}
                  css={"px-8 py-2"}
                  action={onSubmit}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="font-bold border rounded-lg px-4 py-2 text-white">
            <p className="text-center">
              Your request already sent (Request pending). Please wait until
              contact you!
            </p>
            <p className="text-center text-sm">
              requset with phone number: {phone}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookMe;
