import React, { forwardRef, useEffect } from "react";
import NavBar from "../layouts/NavBar";
import Footer from "../layouts/Footer";
import { useDispatch, useSelector } from "react-redux";
import userService from "../services/user";
import { Link, useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { userActions } from "../store/user/user-slice";
import PodcastCard from "../components/ui/PodcastCard";
import ArticleCard from "../components/ui/ArticleCard";
import ProfileChange from "../components/form/ProfileChange";
import IconLogout from "../assets/icons/logout.svg";
import CustomButton from "../components/form/CustomButton";
import Err from "../assets/img/404.svg";

const Error = (props) => {
  return (
    <div className=" min-h-screen">
      <nav className="pt-32">
        <NavBar />
      </nav>

      <main className="responsive ">
        <div className="flex items-center justify-center flex-col">
          <img src={Err} alt="" className="w-[400px] mx-auto" />
          <h1 className="text-3xl font-extrabold mt-10">Whoops...</h1>
          <p className="text-xl font-medium mt-4 mb-6">
            It looks like you're lost
          </p>
          <Link to={"/"}>
            <CustomButton name={"go to home"} css={"px-4 py-2"} />
          </Link>
        </div>
      </main>

      <footer className="mt-20">
        <Footer />
      </footer>
    </div>
  );
};

export default Error;
