import { useEffect, useRef, useState } from "react";
import InputCustom from "../../../components/form/InputCustom";
import { Input, Select, Spinner } from "@chakra-ui/react";
import IconUpload from "../../../assets/icons/upload.svg";
import IconRemove from "../../../assets/icons/bin.svg";
import IconAdd from "../../../assets/icons/add.svg";
import FilesUploadTable from "../ui/FilesUploadTable";
import MultipleFilesUploadTable from "../ui/MultipleFilesUploadTable";
import HtmlEditor from "./HtmlEditor";
import episodeService from "../../../services/episode";
import noteService from "../../../services/note";
import SelectCustom from "../../../components/form/SelectCustom";
import { useDispatch } from "react-redux";
import categoriesService from "../../../services/categories";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import articlesService from "../../../services/articles";
import RichTextEditor from "../ui/RichTextEditor";
import uploadService from "../../../services/upload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { progress } from "framer-motion";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
  ],
};

const AddArticle = () => {
  const editPage = window.location.pathname;

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const toastRef = useRef(null);
  const toastUpdateRef = useRef(null);

  const [number, setNumber] = useState("");
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [duration, setDuration] = useState("");
  const [content, setContent] = useState("");
  const [writerName, setWriterName] = useState("");

  const [image, setImage] = useState("");
  const [writerImage, setWriterImage] = useState("");

  const [fileImgName, setFileImgName] = useState("");
  const [fileImgSize, setFileImgSize] = useState("");

  const [fileWriterImgName, setFileWriterImgName] = useState("");
  const [fileWriterImgSize, setFileWriterImgSize] = useState("");

  const [category, setCategory] = useState([]);
  const [episode, setEpisode] = useState({});

  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  const [newId, setNewId] = useState("");

  const navigate = useNavigate();

  const [msgToast, setMsgToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [titleToast, setTitleToast] = useState("");

  // send request to add or edit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (editPage.includes("edit")) {
      setIsLoading(true);
      articlesService
        .updateArticle(
          id,
          title,
          topic,
          content,
          duration,
          writerName,
          img,
          imgsOne
        )
        .then((res) => {
          setNewId(id);
          setTypeToast("success");
          setTitleToast("Article updated.");
          setMsgToast(`We\'ve update article ${number} for you.`);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      articlesService
        .addArticle(
          number,
          title,
          topic,
          content,
          duration,
          writerName,
          img,
          imgsOne
        )
        .then((res) => {
          setNewId(res.article._id);
          setTypeToast("success");
          setTitleToast("Article created.");
          setMsgToast("We've create your new article for you.");
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // get categories and fill fileds depend the id
  useEffect(() => {
    articlesService.getLast().then((res) => {
      setNumber(
        res.data.length > 0 && res.data[0].articleNumber
          ? res.data[0].articleNumber + 1
          : 1
      );
    });

    categoriesService.getAll().then((res) => {
      setCategory(res.categories);
      setTopic(res.categories[0]._id);
    });

    if (id) {
      articlesService.get(id).then((res) => {
        setTitle(res.article.title);
        setNumber("#" + res.article.articleNumber);
        setContent(res.article.content);
        setTopic(res.article.category._id);
        setImage(res.article.image);
        setDuration(res.article.readTime);
        setWriterName(res.article.writerName);
        setImage(res.article.image.url);
        setFileImgName(res.article.image.name);
        setFileImgSize(res.article.image.size);

        setFile({
          size: res.article.image.size,
          type: "jpg",
          name: res.article.image.name,
        });
        setFilesOne({
          size: res.article.writerImage.size,
          type: "jpg",
          name: res.article.writerImage.name,
        });

        setUploadProgress("100");
        setUploadProgressOne("100");

        setImg(res.article.image);
        setImgsOne(res.article.writerImage);
        // setAudioOne(res.episode.audio);
        // setImageOne(res.episode.image);
      });
    }
  }, []);

  // const [imgs, setImgs] = useState([])

  const fileInputImageRef = useRef(null);
  // const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFileImage, setSelectedFileImage] = useState(null);

  const fileInputWriterImageRef = useRef(null);
  const [uploadProgressWriter, setUploadProgressWriter] = useState(0);
  const [selectedWriterFileImage, setSelectedFileWriterImage] = useState(null);

  const [fileAudioName, setFileAudioName] = useState("");
  const [fileAudioSize, setFileAudioSize] = useState("");

  // show toast msg
  useEffect(() => {
    if (msgToast && titleToast && typeToast) {
      toast({
        title: titleToast,
        description:
          msgToast + " We will redirect you after 3 seconds to detail page",
        status: typeToast,
        duration: 5000,
        isClosable: true,
      });
      if (newId)
        setTimeout(() => {
          navigate(`../articles/${newId}`);
        }, 3000);
    }
  }, [newId, msgToast, titleToast, typeToast]);

  const [file, setFile] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const [img, setImg] = useState();

  const [filesOne, setFilesOne] = useState();
  const [uploadProgressOne, setUploadProgressOne] = useState(0);
  const [imgsOne, setImgsOne] = useState();

  const handleFileChange = (event, writer = false) => {
    const file = event.target.files[0];
    if (file) {
      if (writer) {
        setFilesOne(file);
        setUploadProgressOne(0);
      } else {
        setFile(file);
        setUploadProgress(0);
      }
    }
  };

  const remove = (e, name, writer = false) => {
    e.preventDefault();
    if (writer) {
      setFilesOne(null);
      setImgsOne(null);
      setUploadProgressOne(0);
    } else {
      setFile(null);
      setImg(null);
      setUploadProgress(0);
    }
  };

  const handleUpload = (e, file, name, writer = false) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          const progressPercentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          if (writer) {
            setUploadProgressOne(progressPercentage);
          } else {
            setUploadProgress(progressPercentage);
          }
        },
      })
      .then((response) => {
        if (writer)
          setImgsOne({
            url: response.data.fileName,
            size: file.size,
            name: file.name,
          });
        else
          setImg({
            url: response.data.fileName,
            size: file.size,
            name: file.name,
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <p ref={toastRef}></p>

      <h1 className="mt-6 text-center uppercase text-lg mb-10 border-b border-b-gray-700 w-fit mx-auto pb-4 text-gray-700">
        {editPage.includes("edit") ? "Edit a article" : "Add A New article"}
      </h1>

      <form action="">
        {/* general information  */}
        <div className="px-4 mt-10">
          <Title nbr={1} title={"General Information"} />
          <div className="grid grid-cols-12 gap-x-4 w-full gap-y-5">
            <div className="col-span-2">
              <InputCustom
                title={"Number article"}
                type="text"
                value={"#1"}
                disabled={true}
                item={number}
              />
            </div>
            <div className="col-span-10">
              <InputCustom
                title={"Title"}
                type="text"
                placeholder={"enter title"}
                item={title}
                setItem={setTitle}
                required
              />
            </div>
            <div className="col-span-3">
              <InputCustom
                title={"time read"}
                type="text"
                placeholder={"enter time (12-20 min)"}
                item={duration}
                setItem={setDuration}
                required
              />
            </div>
            <div className="col-span-3">
              {category && (
                <SelectCustom
                  title={"topic"}
                  data={category}
                  item={topic}
                  setItem={setTopic}
                  showAll
                  required
                />
              )}
            </div>
          </div>
        </div>

        {/* writer info */}
        <div className="px-4 mt-10">
          <Title nbr={2} title={"Writer details"} />
          <div className="w-full mb-4">
            <InputCustom
              title={"Writer name"}
              type="text"
              placeholder={"set writer name"}
              item={writerName}
              setItem={setWriterName}
            />
          </div>

          {!imgsOne && (
            <div>
              <input
                ref={fileInputWriterImageRef}
                type="file"
                accept={"image/*"}
                style={{ display: "none" }}
                onChange={(e) => handleFileChange(e, true)}
              />
              <div className="flex items-center">
                <div
                  className="mr-4 border rounded-md bg-primary-50 px-6 py-2 flex items-center justify-center w-fit mb-4 cursor-pointer"
                  onClick={() => fileInputWriterImageRef.current.click()}
                >
                  <img src={IconAdd} alt="" className="w-[20px]" />
                  <p className="text-sm font-medium ml-4 text-white">
                    add image
                  </p>
                </div>
              </div>
            </div>
          )}

          <MultipleFilesUploadTable
            oneFile
            files={filesOne}
            remove={remove}
            uploadProgress={uploadProgressOne}
            update={handleUpload}
            imgs={imgsOne}
            twoImg
            writer
          />
        </div>

        {/* upload files  */}
        <div className="px-4 mt-10">
          <Title nbr={3} title={"Upload Your "} />
          <input
            ref={fileInputImageRef}
            type="file"
            accept={"image/*"}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          {!img && (
            <div>
              <input
                ref={fileInputImageRef}
                type="file"
                accept={"image/*"}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="flex items-center">
                <div
                  className="mr-4 border rounded-md bg-primary-50 px-6 py-2 flex items-center justify-center w-fit mb-4 cursor-pointer"
                  onClick={() => fileInputImageRef.current.click()}
                >
                  <img src={IconAdd} alt="" className="w-[20px]" />
                  <p className="text-sm font-medium ml-4 text-white">
                    add image
                  </p>
                </div>
              </div>
            </div>
          )}

          <MultipleFilesUploadTable
            oneFile
            files={file}
            remove={remove}
            uploadProgress={uploadProgress}
            update={handleUpload}
            imgs={img}
          />
        </div>

        {/* content  */}
        <div className="px-4 mt-10 flex justify-between">
          <div className="w-full">
            <Title nbr={5} title={"content"} />
            <div>
              {/* <RichTextEditor setValue={setContent} value={content}/> */}
              <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
              />
            </div>
          </div>
        </div>

        {/* buttons actions  */}
        <div className="flex items-center justify-end w-full">
          <div className="w-fit flex justify-end px-4 mt-6 mb-4">
            <Link to={"../articles"}>
              <button className="bg-primary-100 rounded-md px-10 py-2 font-semibold text-primary-200 border border-primary-200 bg-white">
                Cancel
              </button>
            </Link>
          </div>
          <div className="w-fit flex justify-end px-4 mt-6 mb-4">
            <button
              disabled={
                !img || !imgsOne || !title || !topic || !content || !duration
              }
              className={`rounded-md px-10 py-[9px] font-semibold text-white bg-primary-200 flex items-center 
                            ${
                              (!img ||
                                !imgsOne ||
                                !title ||
                                !topic ||
                                !content ||
                                !duration) &&
                              "opacity-20"
                            }`}
              onClick={(e) => handleSubmit(e)}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              ) : editPage.includes("edit") ? (
                "Edit article"
              ) : (
                "Add article"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddArticle;

const Title = ({ nbr, title }) => {
  return (
    <div className="flex items-center mt-4 mb-6">
      <div className="mr-3 w-8 h-8 pb-[1px] rounded-full border border-gray-700 flex justify-center items-center text-gray-700">
        {nbr}
      </div>
      <h1 className="text-gray-700 font-medium capitalize">{title}</h1>
    </div>
  );
};

const NoteField = ({ nbr, notes, setNotes }) => {
  const handleChangeInput = (value, type) => {
    setNotes(
      notes.map((item, idx) => {
        if (nbr === idx) {
          switch (type) {
            case "note":
              return { ...item, note: value };
            case "hour":
              return {
                ...item,
                hour:
                  value >= 24 ? 23 : value.length === 1 ? `0${value}` : value,
              };
            case "min":
              return {
                ...item,
                minute:
                  value >= 60 ? 59 : value.length === 1 ? `0${value}` : value,
              };
            case "sec":
              return {
                ...item,
                second:
                  value >= 60 ? 59 : value.length === 1 ? `0${value}` : value,
              };
          }
        }
        return item;
      })
    );
  };

  const handleRemove = () => {
    noteService.deleteNote(notes[nbr].id).then((res) => console.log(res));
    if (notes.length === 1)
      setNotes([{ note: "", hour: "00", minute: "00", second: "00" }]);
    else setNotes(notes.filter((item, idx) => nbr !== idx));
  };

  return (
    <div className="flex items-center mb-4">
      <div className="grid grid-cols-12 gap-x-4 w-full">
        <div className="col-span-6">
          <InputCustom
            title={"Note " + (nbr + 1)}
            type="text"
            placeholder={"enter new note"}
            item={notes[nbr].note}
            setItem={(val) => handleChangeInput(val, "note")}
          />
        </div>
        <div className="col-span-2">
          <InputCustom
            title={"Hours"}
            type="number"
            min={0}
            max={23}
            item={notes[nbr].hour}
            setItem={(val) => handleChangeInput(val, "hour")}
          />
        </div>
        <div className="col-span-2">
          <InputCustom
            title={"Minutes"}
            type="number"
            min={0}
            max={59}
            item={notes[nbr].minute}
            setItem={(val) => handleChangeInput(val, "min")}
          />
        </div>
        <div className="col-span-2">
          <InputCustom
            title={"Seconds"}
            type="number"
            min={0}
            max={59}
            item={notes[nbr].second}
            setItem={(val) => handleChangeInput(val, "sec")}
          />
        </div>
      </div>
      <div className="flex items-center justify-end w-[50px] h-full py-3">
        <img
          src={IconRemove}
          alt=""
          className="w-[20px] cursor-pointer"
          onClick={() => handleRemove()}
        />
      </div>
    </div>
  );
};
