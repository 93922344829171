import React from "react";
import Card from "./Card";
import QuotesIcon from "../../assets/icons/quotes.svg";
import { useSelector } from "react-redux";

const AboutSection = () => {
  const data = useSelector((state) => state.setting.item);
  const dataLoading = useSelector((state) => state.setting.status);

  if (!dataLoading) return null;

  return (
    <div className="section">
      <h1 className="font-extrabold capitalize text-xl md:text-3xl text-center md:text-left text-primary-50">
        About Us
      </h1>
      <div className="flex flex-col md:flex-row mt-10">
        <Card
          bgImg={require("../../assets/img/about.jpg")}
          css={"mt-4 md:mt-0 order-2 md:order-1 md:h-[550px] md:w-[400px]"}
        />
        <div className="md:w-3/5 md:pl-20 order-1 md:order-2">
          <img
            src={QuotesIcon}
            alt=""
            className="w-[40px] md:w-[70px] md:mb-6"
          />
          <p className="text-center md:text-justify">
            <div
              dangerouslySetInnerHTML={{ __html: data.aboutUs.description }}
            />
          </p>
          <p className="capitalize mt-6 font-semibold text-center md:text-left">
            {data.aboutUs.name}
          </p>
          <p className="text-primary-200 text-sm text-center md:text-left">
            {data.aboutUs.address}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
