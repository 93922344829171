import { createSlice } from "@reduxjs/toolkit";

const listBookMeSlice = createSlice({
  name: "listBookMe",
  initialState: {
    itemsList: [],
    status: null,
  },
  reducers: {
    replaceData(state, action) {
      state.itemsList = action.payload;
    },
    replaceStatus(state, action) {
      state.itemsList = state.itemsList.map((item) =>
        item._id === action.payload.id
          ? { ...item, status: action.payload.status }
          : item
      );
    },
    dataLoading(state, action) {
      state.status = "loading";
    },
    removeData(state, action) {
      state.itemsList = state.itemsList.filter(
        (item) => item.id !== action.payload
      );
    },
    toggleStatus(state, action) {
      state.itemsList = state.itemsList.map((item) =>
        item.id === action.payload ? { ...item, status: !item.status } : item
      );
    },
    clearAll(state, action) {
      state.itemsList = [];
      state.status = null;
    },
  },
});

export const listBookMeActions = listBookMeSlice.actions;

export default listBookMeSlice;
