import React from "react";
import Card from "./Card";
import QuotesIcon from "../../assets/icons/quotes.svg";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const PostSection = () => {
  const data = useSelector((state) => state.setting.item);
  const dataLoading = useSelector((state) => state.setting.status);

  if (!dataLoading) return null;

  return (
    <div className="relative py-px">
      {/* <img src={ImgSection} alt="" className='brightness-[.3] absolute left-0 top-0 w-full h-full object-cover'/> */}
      <div className="responsive">
        <h1 className="font-extrabold capitalize text-xl md:text-3xl text-center md:text-left text-primary-50">
          latest social posts
        </h1>
        <div className="w-full mt-10">
          {/* <Card bgImg={''} css={'mt-4 md:mt-0 order-2 md:order-1 md:h-[450px] md:w-[400px]'} /> */}

          <Swiper
            slidesPerView={3.8}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            // modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <Card
                bgImg={require("../../assets/img/Image - stories.jpg")}
                css={
                  "mt-4 md:mt-0 order-2 md:order-1 md:h-[350px] md:w-[250px]"
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                bgImg={require("../../assets/img/Image - stories (1).jpg")}
                css={
                  "mt-4 md:mt-0 order-2 md:order-1 md:h-[350px] md:w-[250px]"
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                bgImg={require("../../assets/img/Image - stories (1).jpg")}
                css={
                  "mt-4 md:mt-0 order-2 md:order-1 md:h-[350px] md:w-[250px]"
                }
              />
            </SwiperSlide>
            <SwiperSlide>
              <Card
                bgImg={require("../../assets/img/Image - stories (1).jpg")}
                css={
                  "mt-4 md:mt-0 order-2 md:order-1 md:h-[350px] md:w-[250px]"
                }
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default PostSection;
