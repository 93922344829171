import React, { useState } from "react";
import CustomButton from "./CustomButton";
import { Link } from "react-router-dom";

const SubscribeForm = ({ css = "justify-center md:justify-start" }) => {
  const [email, setEmail] = useState("");

  return (
    <form action="" className={`flex mt-4 md:mt-0 ${css}`}>
      <input
        type="text"
        className="rounded-lg w-1/2 px-4 text-sm outline-none text-black"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          localStorage.setItem("email-subscribe", e.target.value);
        }}
        placeholder="your email"
      />
      <Link to={"/register"}>
        <CustomButton
          name={"sign up"}
          css={"px-4 font-medium py-2 rounded-lg ml-4 text-sm"}
        />
      </Link>
    </form>
  );
};

export default SubscribeForm;
