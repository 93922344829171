import { createSlice } from '@reduxjs/toolkit';

const episodeSlice = createSlice({
    name: 'episode',
    initialState: {
        item: null,
        audio: null,
        status: null
    },
    reducers: {
        replaceData(state, action) {
            state.item = action.payload;
        },
        replaceAudio(state, action) {
            state.audio = action.payload;
        },
        dataLoading(state, action) {
            state.status = 'loading';
        },
    },
});

export const episodeActions = episodeSlice.actions;

export default episodeSlice;
