import React, { useEffect } from "react";
import CustomButton from "../components/form/CustomButton";
import { Link, NavLink } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  forwardRef,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import categoriesService from "../services/categories";
import { listCategoryActions } from "../store/category/list-category-slice";
import { useState } from "react";
import IconDown from "../assets/icons/down.svg";
import IconUser from "../assets/icons/user.svg";
import IconMenu from "../assets/icons/menu.svg";
import Logo from "../assets/logo/logo_name.png";
import NavBarMobile from "./NavBarMobile";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Filter from "./Filter";
import MediaDetail from "./MediaDetail";
import Media from "./Media";
import { authActions } from "../store/auth/auth-slice";

const NavBar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const dispatch = useDispatch();

  const pathUrl = window.location.pathname;
  const [categories, setCategories] = useState([]);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) dispatch(authActions.login(JSON.parse(user)));
  }, []);

  useEffect(() => {
    categoriesService.getAll().then((res) => {
      setCategories(res.categories);
    });
  }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarClass = isScrolled
    ? "fixed-navbar"
    : "absolute top-0 left-0 right-0 z-50 bg-white";
  const navbarPaddingClass = isScrolled ? "py-2" : "py-6";

  const audio = useSelector((state) => state.audio.audio);

  return (
    <>
      {/* <div className={`w-full fixed z-50 left-0 top-0 ${scrolled ? 'bg-gray-700' : 'bg-gray-800'}`}> */}
      <div className={`bg-transparent ${navbarClass}`}>
        <div className="flex items-center justify-between responsive py-6">
          <h1 className="text-lg md:text-2xl font-bold w-1/2">
            {/* <Link to={'/'}>Kool health</Link> */}
            <Link to={"/"}>
              <img src={Logo} alt="" className="w-32 mt-1" />
            </Link>
          </h1>
          {/* hrefs navbars */}
          <div className="hidden md:flex justify-between w-2/3 items-center">
            <ul className="flex justify-between w-2/3 items-center mr-8 space-x-6">
              <li className="uppercase cursor-pointer font-bold">
                <NavLink to="/">home</NavLink>
              </li>
              <li className="uppercase font-bold cursor-pointer">
                <Menu>
                  {({ isOpen }) => (
                    <>
                      <MenuButton isActive={isOpen} as={null} rightIcon={null}>
                        <div className="flex items-center uppercase">
                          <p>Topics</p>
                          <img
                            src={IconDown}
                            alt=""
                            className="ml-2 w-3 mt-1"
                          />
                        </div>
                      </MenuButton>
                      <MenuList
                        border={"none"}
                        bgColor={"white"}
                        borderColor={"red"}
                      >
                        {categories &&
                          categories.map((item) => (
                            <MenuItem key={item._id} width={"100%"}>
                              <Link
                                to={`/topics/${item._id}`}
                                className="w-full"
                              >
                                {item.title}
                              </Link>
                            </MenuItem>
                          ))}
                        <MenuItem
                          borderTopWidth={"1px"}
                          pt={"10px"}
                          mt={"10px"}
                          width={"100%"}
                        >
                          <Link to={"/topics"} className="w-full">
                            All Topics
                          </Link>
                        </MenuItem>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </li>
              <li className="uppercase cursor-pointer font-bold">
                <NavLink to="/podcasts">podcasts</NavLink>
              </li>
              <li className="uppercase cursor-pointer font-bold">
                <NavLink to="/articles">articles</NavLink>
              </li>
              <li className="uppercase cursor-pointer font-bold">
                <NavLink to="/notes">notes</NavLink>
              </li>
            </ul>
            <div className="flex items-center">
              {user ? (
                <Link to={"/profile"}>
                  <h3 className="cursor-pointer capitalize font-medium">
                    welcome, {user.username}
                  </h3>
                </Link>
              ) : (
                <>
                  <CustomButton
                    name={"Login"}
                    css={"px-4 py-2"}
                    cssTitle={"text-white"}
                    href={"/login"}
                  />
                  <div className="mx-2"></div>
                  <CustomButton
                    name={"Register"}
                    css={"bg-white px-4 py-2"}
                    cssTitle={"text-primary-200"}
                    href={"/register"}
                  />
                </>
              )}
            </div>
          </div>
          {/* drawer in mobile view  */}
          <div>
            <div ref={btnRef} onClick={onOpen} className="md:hidden">
              <img src={IconMenu} alt="" className="w-6" />
            </div>
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay />
              <DrawerContent bgColor={"gray.700"}>
                <DrawerCloseButton />
                <DrawerHeader></DrawerHeader>

                <DrawerBody>
                  {(pathUrl.includes("archives") ||
                    pathUrl.includes("articles")) && (
                    <>
                      <Filter gridDisplay={null} setGridDisplay={null} />
                      <button
                        className="bg-primary-200 rounded-lg font-medium w-full py-2 text-white mb-8"
                        onClick={onClose}
                      >
                        Filter
                      </button>
                    </>
                  )}
                  {/* {audio && (
                    <>
                      <Media ref={ref} type="phone" />
                    </>
                  )} */}
                </DrawerBody>

                <DrawerFooter>
                  <div className="w-full">
                    {user ? (
                      <Link to={"/profile"}>
                        <h3 className="cursor-pointer capitalize font-medium text-center">
                          welcome, {user.username} <br />
                          <span className="underline"> Your profile</span>
                        </h3>
                      </Link>
                    ) : (
                      <>
                        <CustomButton
                          name={"Login"}
                          css={"px-4 py-2"}
                          cssTitle={"text-white"}
                          href={"./login"}
                        />
                        <div className="mx-2"></div>
                        <CustomButton
                          name={"Register"}
                          css={"bg-white px-4 py-2"}
                          cssTitle={"text-primary-200"}
                          href={"./register"}
                        />
                      </>
                    )}
                  </div>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
      </div>
      <NavBarMobile />

      {/* media player  */}
      {/* {true && audio && <Media ref={ref} />} */}
    </>
  );
};

export default NavBar;
