import React from "react";

const CommentBox = ({ item }) => {
  const date = new Date(item.createdAt);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className="flex border rounded-lg border-gray-500 px-6 py-4 mb-4">
      <div className="w-[30px] md:w-[50px] h-[30px] md:h-[45px] bg-primary-50 flex items-center justify-center rounded-full mr-4 md:mr-8">
        <p className="uppercase font-semibold text-white">
          {item.user.username ? item.user.username.substring(0, 1) : "U"}
        </p>
      </div>
      {/* <img src={require('../../assets/img/image 5.png')} alt="" className='w-[30px] md:w-[50px] h-[30px] md:h-[50px] object-cover rounded-full mr-4 md:mr-8' /> */}
      <div className="w-full">
        <div className="flex items-center mb-4 justify-between">
          <h1 className="text-sm md:text-lg font-semibold">{item.user.name}</h1>
          <p className="font-light ml-4 text-xs md:text-sm capitalize">
            on {formattedDate}
          </p>
        </div>
        <p className="text-gray-500 text-xs md:text-sm">{item.content}</p>
      </div>
    </div>
  );
};

export default CommentBox;
