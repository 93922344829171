import React, { useRef } from "react";
import Card from "../components/ui/Card";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import categoriesService from "../services/categories";
import { useDispatch, useSelector } from "react-redux";
import { categoryActions } from "../store/category/category-slice";
import { forwardRef } from "react";
import PodcastCard from "../components/ui/PodcastCard";
import ArticleCard from "../components/ui/ArticleCard";
import { listEpisodeActions } from "../store/episode/list-episode-slice";
import { listArticleActions } from "../store/article/list-article-slice";
import Footer from "../layouts/Footer";
import NavBar from "../layouts/NavBar";
import EmptySection from "../components/ui/EmptySection";
import { audioActions } from "../store/episode/audio-slice";
import Media from "../layouts/Media";

const TopicDetail = (props) => {
  const admin = props.admin ?? false;
  const { id } = useParams();

  const dispatch = useDispatch();
  const audioRef = useRef();
  const audio = useSelector((state) => state.audio.audio);
  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");

    dispatch(audioActions.replacePercentage(+percent));
    dispatch(audioActions.replaceCurrentTime(`${hours}:${minutes}:${seconds}`));
  };

  useEffect(() => {
    dispatch(audioActions.clearData());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    categoriesService.get(id).then((res) => {
      dispatch(listEpisodeActions.clearAll());
      dispatch(listArticleActions.clearAll());

      dispatch(categoryActions.replaceData(res.category));
      dispatch(categoryActions.dataLoading());

      dispatch(listEpisodeActions.replaceData(res.episodes.list));
      dispatch(listEpisodeActions.dataLoading());

      dispatch(listArticleActions.replaceData(res.articles.list));
      dispatch(listArticleActions.dataLoading());
    });
  }, [id]);

  return (
    <div className=" min-h-screen">
      {/* {audio && ( */}
      <audio
        ref={audioRef}
        onTimeUpdate={getCurrDuration}
        src={process.env.REACT_APP_FILES_URL + "/" + audio}
      ></audio>
      {/* )} */}
      {/* media player  */}
      {true && audio && <Media ref={audioRef} />}

      {!admin && (
        <nav>
          <NavBar />
        </nav>
      )}

      <Header />
      <main className={`${!admin ? "responsive pt-4 md:pt-8 pb-20" : "pt-8"}`}>
        <ListPodcast ref={audioRef} />
        <ListArticle />
      </main>

      {!admin && (
        <footer>
          <Footer />
        </footer>
      )}
    </div>
  );
};

export default TopicDetail;

const Header = () => {
  const topic = useSelector((state) => state.category.item);
  const statusTopic = useSelector((state) => state.category.status);

  if (!statusTopic) return <>loading...</>;

  return (
    <div>
      <img
        src={process.env.REACT_APP_FILES_URL + "/" + topic.image.url}
        alt=""
        className="h-[500px] w-full object-cover"
      />
      {/* <Card
        css={"h-[500px]"}
        bgImg={process.env.REACT_APP_FILES_URL + "/" + topic.image.url}
      ></Card> */}
      <div className="responsive">
        <h4 className="text-center mt-8 text-sm font-medium uppercase">
          topic
        </h4>
        <h1 className="text-center mt-4 font-bold text-3xl capitalize">
          {topic.title}
        </h1>
        <p className="font-light mt-4 md:mt-10 text-center text-xs md:text-base">
          {topic.description}
        </p>
      </div>
    </div>
  );
};

const ListPodcast = forwardRef((props, ref) => {
  const topic = useSelector((state) => state.category.item);
  const statusTopic = useSelector((state) => state.category.status);

  const listEpisode = useSelector((state) => state.listEpisode.itemsList);
  const statusListEpisode = useSelector((state) => state.listEpisode.status);

  if (!statusListEpisode) return null;

  if (listEpisode.length === 0) return <EmptySection title="podcasts" />;

  return (
    <div className="mt-10 !mb-10">
      <h1 className="text-2xl font-semibold capitalize text-center md:text-left">
        podcasts
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-6 mt-6">
        {statusTopic &&
          statusListEpisode &&
          listEpisode.map((item) => (
            <PodcastCard
              key={item._id}
              item={{ ...item, category: { title: topic.title } }}
              gridDisplay={true}
              ref={ref}
            />
          ))}
      </div>
    </div>
  );
});

const ListArticle = forwardRef((props, ref) => {
  const statusTopic = useSelector((state) => state.category.status);

  const listArticle = useSelector((state) => state.listArticle.itemsList);
  const statusListArticle = useSelector((state) => state.listArticle.status);

  if (listArticle.length === 0) return <EmptySection title="articles" />;

  return (
    <div className="mt-10 !mb-10">
      <h1 className="text-2xl font-semibold capitalize text-center md:text-left">
        articles
      </h1>
      <div className="grid grid-cols-1 mt-6 gap-y-4">
        {statusTopic &&
          statusListArticle &&
          listArticle.map((item) => (
            <>
              <div className="block md:hidden">
                <ArticleCard key={item._id} item={item} ref={ref} grid />
              </div>
              <div className="hidden md:block">
                <ArticleCard key={item._id} item={item} ref={ref} />
              </div>
            </>
          ))}
      </div>
    </div>
  );
});
