import axios from 'axios';
import { json } from 'react-router-dom';

const url = process.env.REACT_APP_API_URL

const getAll = (limit = 100, search = '', startDate = '', endDate = '', duration, topic, status='') => {
  const querySearch = search ? `&search=${search}` : ''
  const queryDuration = duration ? `&duration=${duration}` : ''
  const queryTopic = topic ? `&category=${topic}` : ''
  const queryDateStart = startDate ? `&startDate=${startDate}` : ''
  const queryDateEnd = endDate ? `&endDate=${endDate}` : ''
  const queryStatus = status !== '' ? `&isPublished=${status}` : ''

  return axios
    .get(`${url}/episodes?limit=${limit}${querySearch}${queryDuration}${queryTopic}${queryDateStart}${queryDateEnd}${queryStatus}`)
    .then((response) => response.data)
    .catch((error) => json(error));
}

const get = (id) => axios
  .get(`${url}/episodes/${id}`)
  .then((response) => response.data)
  .catch((error) => json(error));

const getLast = () => axios
  .get(`${url}/episodes/last`)
  .then((response) => response.data)
  .catch((error) => json(error));

const getMostPlayed = (limit=5) => axios
  .get(`${url}/episodes/trending?limit=${limit}`)
  .then((response) => response.data)
  .catch((error) => json(error));

const getSimilar = (id, limit=6) => axios
  .get(`${url}/episodes/similair/${id}?limit=${limit}`)
  .then((response) => response.data)
  .catch((error) => json(error));  

const getNeighbours = (number) => axios
  .get(`${url}/episodes/neighbors/${number}`)
  .then((response) => response.data)
  .catch((error) => json(error));
  
const deleteEpisode = (id) => axios
  .delete(`${url}/episodes/${id}`)
  .then(() => json({data: 'success'}))
  .catch((error) => json(error));
  
const addEpisode = ( number, title, topic, audioOne, ImageOne, description, explication, duration) => axios
  .post(`${url}/episodes`, {
    episodeNumber: number,
    title: title,
    category: topic,
    image: ImageOne,
    audio: audioOne,
    description: description,
    smallDescription: 'testest???????',
    explication: explication,
    duration: duration
  })
  .then((res) => res.data)
  .catch((error) => json(error));
  
const updateEpisode = (id, title, topic, audioOne, ImageOne, description, explication, duration) => axios
  .patch(`${url}/episodes/${id}`, {
    title: title,
    category: topic,
    image: ImageOne,
    audio: audioOne,
    description: description,
    smallDescription: 'testest???????',
    explication: explication,
    duration: duration
  })
  .then((res) => res.data)
  .catch((error) => json(error));
  
const toggleEpisode = (id) => axios
  .put(`${url}/episodes/isPublished/${id}`)
  .then((res) => res.data)
  .catch((error) => json(error));
  
const addEpisodeFavorite = (id, token) => {    
    return axios
        .put(`${url}/episodes/favorites/${id}`,null,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => response.data)
        .catch((error) => json(error));
}
    
const checkEpisodeFavorite = (id, token) => {    
    return axios
        .get(`${url}/episodes/${id}/check-favorite`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => response.data)
        .catch((error) => json(error));
    }
    
const deleteEpisodeFavorite = (id, token) => {    
    return axios
        .delete(`${url}/episodes/favorites/${id}`,
        {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => response.data)
        .catch((error) => json(error));
}

const episodesService = {
  getAll,
  get,
  getLast,
  getMostPlayed,
  getSimilar,
  getNeighbours,
  deleteEpisode,
  addEpisode,
  updateEpisode,
  toggleEpisode,

  addEpisodeFavorite,
  deleteEpisodeFavorite,
  checkEpisodeFavorite
};

export default episodesService
