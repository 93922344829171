import { createSlice } from '@reduxjs/toolkit';

const listCategorySlice = createSlice({
    name: 'listCategory',
    initialState: {
        itemsList: [],
        status: null
    },
    reducers: {
        replaceData(state, action) {
            state.itemsList = action.payload;
        },
        dataLoading(state, action) {
            state.status = 'loading';
        },
        removeData(state, action) {
            state.itemsList = state.itemsList.filter(item => item._id !== action.payload);
        },
        toggleStatus(state, action) {
            state.itemsList = state.itemsList.map(item => item._id === action.payload ? {...item, isPublished: !item.isPublished} : item);
        },
    },
});

export const listCategoryActions = listCategorySlice.actions;

export default listCategorySlice;
