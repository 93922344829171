import React from "react";
import { Link } from "react-router-dom";
import ArticleIcon from "../../assets/icons/article.svg";
import PodcastIcon from "../../assets/icons/podcasts.svg";

const TopicCard = ({ item, grid = false, removeBorder = false }) => {
  const url = window.location.pathname;

  return (
    <div>
      <div
        className={`bg-white rounded-xl p-4 flex items-center ${
          url.includes("admin") && !removeBorder && "border border-gray-400"
        }`}
      >
        <div
          className={`p-[6px] shadow-lg w-[70px] h-[70px] bg-[#ffffff40] rounded-full mr-6`}
        >
          <img
            src={process.env.REACT_APP_FILES_URL + "/" + item.image.url}
            alt=""
            className="rounded-full h-full w-full object-cover"
          />
        </div>
        <div className={``}>
          {url.includes("/groups") ? (
            <h3 className="font-semibold text-md capitalize">{item.title}</h3>
          ) : (
            <Link to={`./${item._id}`}>
              <h3 className="hover:underline font-semibold text-md capitalize">
                {item.title}
              </h3>
            </Link>
          )}
          <div className="flex items-center mt-2">
            <img src={ArticleIcon} alt="" className="w-4 mr-1" />
            <p className="text-xs font-light">{item.articleCount} articles</p>
          </div>
          <div className="flex items-center mt-2">
            <img src={PodcastIcon} alt="" className="w-4 mr-1" />
            <p className="text-xs font-light">{item.episodeCount} podcasts</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicCard;
