import { forwardRef, useEffect, useRef } from "react";
import AboutSection from "../components/ui/AboutSection";
import ArticleSection from "../components/ui/ArticleSection";
import EndorsementsSection from "../components/ui/EndorsementsSection";
import EpisodeSection from "../components/ui/EpisodeSection";
import IntroSection from "../components/ui/IntroSection";
import PodcastSection from "../components/ui/PodcastSection";
import ShapesCustom from "../components/ui/Shapes";
import SliderHome from "../components/ui/SliderHome";
import TopicsSection from "../components/ui/TopicsSection";
import Footer from "../layouts/Footer";
import NavBar from "../layouts/NavBar";
import { useDispatch, useSelector } from "react-redux";
import episodeService from "../services/episode";
import { listEpisodeActions } from "../store/episode/list-episode-slice";
import settingsService from "../services/settings";
import { settingActions } from "../store/setting/setting-slice";
import PostSection from "../components/ui/PostSection";
import CustomButton from "../components/form/CustomButton";
import BookMe from "../components/form/BookMe";
import Media from "../layouts/Media";
import { audioActions } from "../store/episode/audio-slice";

const Home = (props) => {
  const dispatch = useDispatch();
  const audioRef = useRef();
  const audio = useSelector((state) => state.audio.audio);
  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");

    dispatch(audioActions.replacePercentage(+percent));
    dispatch(audioActions.replaceCurrentTime(`${hours}:${minutes}:${seconds}`));
  };

  useEffect(() => {
    dispatch(audioActions.clearData());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    episodeService
      .getAll()
      .then((res) => {
        dispatch(listEpisodeActions.replaceData(res.episodes.slice(0, 4)));
        dispatch(listEpisodeActions.dataLoading());
      })
      .catch((err) => {
        console.log(err);
      });

    settingsService.getInfo().then((res) => {
      console.log(res.info);
      dispatch(settingActions.replaceData(res.info));
      dispatch(settingActions.dataLoading());
    });
  }, [dispatch]);

  return (
    <div className="w-full overflow-x-hidden min-h-screen">
      <nav className="">
        <NavBar />
      </nav>

      <main className="">
        <SliderHome />

        <div id="intro"></div>
        <div className="responsive relative">
          <PodcastSection />

          <div className="relative">
            <EndorsementsSection />
          </div>

          <div className="relative">
            <EpisodeSection ref={audioRef} />
          </div>

          <div className="relative">
            <TopicsSection />
          </div>

          {/* <ArticleSection /> */}
        </div>

        <div className="relative mb-20 -mt-16 flex items-center justify-between rounded-lg backdrop-blur-lg backdrop-filter backdrop-brightness-[.1]">
          <img
            src={require("../assets/img/sport.jpg")}
            alt=""
            className="w-full h-[280px] md:h-[400px] object-cover brightness-[.4]"
          />
          <div className="absolute left-0 top-0 right-0 flex flex-col items-center justify-center h-full">
            <h1 className="font-bold text-2xl md:text-5xl mb-4 text-primary-50">
              You need a coach!
            </h1>
            <p className="text-xs md:text-lg text-center md:w-2/3 mb-10 text-white">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis
              facere, cum est eaque incidunt, numquam illo hic quam culpa dicta
              exercitationem maxime quia nulla illum ducimus nesciunt error
              iure? Autem.
            </p>
            <BookMe />
          </div>
        </div>

        <div className="relative hidden md:block">
          <PostSection />
        </div>

        <div className="responsive">
          <div className="relative">
            <AboutSection />
          </div>
        </div>
      </main>

      <footer>
        <Footer />
      </footer>

      {/* {audio && ( */}
      <audio
        ref={audioRef}
        onTimeUpdate={getCurrDuration}
        src={process.env.REACT_APP_FILES_URL + "/" + audio}
      ></audio>
      {/* )} */}
      {/* media player  */}
      {true && audio && <Media ref={audioRef} />}
    </div>
  );
};

export default Home;
