import React from "react";
import Icon from "../../assets/icons/add.svg";
import IconDashboard from "../../assets/icons/dashboard.svg";
import IconPodcast from "../../assets/icons/podcasts.svg";
import IconTopic from "../../assets/icons/topic.svg";
import IconArticle from "../../assets/icons/article.svg";
import IconUser from "../../assets/icons/profile.svg";
import IconComment from "../../assets/icons/comment.svg";
import IconSettings from "../../assets/icons/setting.svg";
import IconStats from "../../assets/icons/stats.svg";
import IconLogout from "../../assets/icons/logout.svg";
import IconGym from "../../assets/icons/gym.svg";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

const SideBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const styleSelect = "border-r-2 border-primary-200";

  const pathname = window.location.pathname;

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="">
      <img
        src={require("../../assets/logo/logo.png")}
        className="w-[100px] mx-auto mb-4"
        alt=""
      />
      <div
        className="pl-4 2xl:pl-8 mt-0
      "
      >
        <h1 className="capitalize text-xs 2xl:text-lg font-semibold mb-2">
          menu
        </h1>
        <ul>
          <li className={pathname.endsWith("admin") ? styleSelect : ""}>
            <Item
              title={"dashboard"}
              select={pathname.endsWith("admin")}
              url="/kxo7aw80p5b9ldq/admin"
            />
          </li>
          <li className={pathname.endsWith("articles") ? styleSelect : ""}>
            <Item
              select={pathname.includes("articles")}
              title={"articles"}
              url="/kxo7aw80p5b9ldq/admin/articles"
            />
          </li>
          <li className={pathname.includes("podcasts") ? styleSelect : ""}>
            <Item
              select={pathname.includes("podcasts")}
              title={"podcasts"}
              url="/kxo7aw80p5b9ldq/admin/podcasts"
            />
          </li>
          <li className={pathname.includes("topics") ? styleSelect : ""}>
            <Item
              select={pathname.includes("topics")}
              title={"topics"}
              url="/kxo7aw80p5b9ldq/admin/topics"
            />
          </li>
        </ul>

        <h1 className="capitalize text-xs 2xl:text-lg font-semibold mt-8 mb-2">
          library
        </h1>
        <ul>
          <li className={pathname.includes("bookme") ? styleSelect : ""}>
            <Item
              select={pathname.includes("bookme")}
              title={"book me"}
              url="/kxo7aw80p5b9ldq/admin/bookme"
            />
          </li>
          <li className={pathname.includes("users") ? styleSelect : ""}>
            <Item
              select={pathname.includes("users")}
              title={"users"}
              url="/kxo7aw80p5b9ldq/admin/users"
            />
          </li>
          <li className={pathname.includes("comments") ? styleSelect : ""}>
            <Item
              select={pathname.includes("comments")}
              title={"comments"}
              url="/kxo7aw80p5b9ldq/admin/comments"
            />
          </li>
          <li className={pathname.includes("stats") ? styleSelect : ""}>
            <Item
              select={pathname.includes("stats")}
              title={"stats"}
              url="/kxo7aw80p5b9ldq/admin/stats"
            />
          </li>
        </ul>

        <h1 className="capitalize text-xs 2xl:text-lg font-semibold mt-8 mb-2">
          others
        </h1>
        <ul>
          {/* <li className={pathname.includes("settings") && styleSelect}>
            <Item
              select={pathname.includes("settings")}
              title={"settings"}
              url="/admin/settings"
            />
          </li> */}
          <li className="" onClick={onOpen}>
            <Item title={"logout"} />
          </li>
        </ul>

        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
          <ModalOverlay />
          <ModalContent overflow={"hidden"} rounded={"10"}>
            <ModalHeader>Log out</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <h1 className="text-lg font-semibold">Are you sure ?</h1>
              <div className="flex items-center justify-between">
                <button
                  onClick={onClose}
                  className="mt-6 p-3 border border-primary-50 rounded-lg w-[45%]"
                >
                  Cancel
                </button>
                <button
                  onClick={handleLogout}
                  className="mt-6 bg-primary-50 text-white p-3 rounded-lg w-[45%]"
                >
                  Confirm
                </button>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default SideBar;

const Item = ({ title, url = "/admin", select = false }) => {
  var Img;
  // eslint-disable-next-line default-case
  switch (title) {
    case "book me":
      Img = (
        <img
          src={IconGym}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "podcasts":
      Img = (
        <img
          src={IconPodcast}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "articles":
      Img = (
        <img
          src={IconArticle}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "topics":
      Img = (
        <img
          src={IconTopic}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "users":
      Img = (
        <img
          src={IconUser}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "comments":
      Img = (
        <img
          src={IconComment}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "stats":
      Img = (
        <img
          src={IconStats}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "settings":
      Img = (
        <img
          src={IconSettings}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    case "logout":
      Img = (
        <img
          src={IconLogout}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
      break;
    default:
      Img = (
        <img
          src={IconDashboard}
          alt=""
          className="w-[24px] h-[24px] 2xl:w-[30px] 2xl:h-[30px] pt-1"
        />
      );
  }

  return title === "logout" ? (
    <div className="cursor-pointer flex items-center py-2 2xl:py-4 hover:bg-primary-200 hover:bg-opacity-40">
      {Img}
      <p className="capitalize mt-[2px] text-sm 2xl:text-xl ml-2 font-bold">
        {title}
      </p>
    </div>
  ) : select ? (
    <Link to={url}>
      <div className="flex items-center py-2 2xl:py-4">
        {Img}
        <p className="capitalize mt-[2px] text-sm 2xl:text-xl ml-2 font-bold text-black">
          {title}
        </p>
      </div>
    </Link>
  ) : (
    <Link to={url}>
      {/* <Link to={url}> */}
      <div className="flex items-center py-2 2xl:py-4 hover:bg-primary-200 hover:bg-opacity-40">
        {/* <img src={} alt="" className='w-[20px] h-[20px]'/> */}
        {Img}
        <p className="capitalize mt-[3px] text-sm 2xl:text-xl ml-2 font-semi-bold">
          {title}
        </p>
      </div>
    </Link>
  );
};
