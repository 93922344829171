import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth";
import { Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth/auth-slice";

const Login = () => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErr(false);
    setLoading(true);

    try {
      // Make your request here using fetch or any other HTTP library
      authService.login(username, password).then((res) => {
        if (
          res.response &&
          res.response.status &&
          res.response.status === 400
        ) {
          setErr(res.response.data.message);
          setLoading(false);
        } else {
          localStorage.setItem("user", JSON.stringify(res.data));
          dispatch(authActions.login(res.data));
          setLoading(false);
          navigate("/");
        }
      });
    } catch (error) {
      setErr(error.message);
      setLoading(false);
    }
  };

  return (
    <form
      action=""
      className="grid grid-cols-1 w-[300px]"
      onSubmit={handleSubmit}
    >
      {err && (
        <label
          htmlFor=""
          className="bg-red-200 border border-red-600 rounded-lg px-4 py-2 text-sm w-full mb-4 text-red-600"
        >
          {err}
        </label>
      )}
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="enter your username"
        className="rounded-md border border-gray-300 mb-4 w-full outline-none px-4 py-2"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="enter your password"
        className="rounded-md border border-gray-300 mb-2 w-full outline-none px-4 py-2"
      />
      {/* <div className="flex items-center justify-between w-full">
        <p className="text-sm text-primary-200">forgot password?</p>
      </div> */}
      <div className="rounded-lg bg-primary-200 overflow-hidden w-full mt-10 font-bold flex items-center justify-center">
        {loading ? (
          <div className="px-4 py-[12px]">
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="md"
            />
          </div>
        ) : (
          <button className="w-full h-full py-2 px-4 text-white">Login</button>
        )}
      </div>
    </form>
  );
};

export default Login;
