import CustomButton from "../form/CustomButton";
import PodcastPlatform from "./PodcastPlatform";

const SliderHome = () => {
  return (
    <div className="w-full h-screen bg-gradient-to-tr from-primary-300 to-secondary-200 flex items-center justify-center flex-col relative overflow-hidden">
      <img
        // src={require("../../assets/img/podcast.jpg")}
        src={require("../../assets/img/_VIC8478-24.jpg")}
        alt=""
        className="absolute left-0 top-0 bottom-0 right-0 object-cover brightness-[.4] h-full w-full"
      />
      <div className="relative z-30 w-full flex flex-col items-center mt-10">
        {/* <img
          src={require("../../assets/logo/logo.png")}
          alt=""
          className="mb-8"
        /> */}
        <h1 className="text-primary-50 font-bold capitalize text-3xl md:text-5xl border-l-8 pl-4 mb-4">
          Kool Health
        </h1>
        <p className="text-lg w-1/3 text-center font-medium text-white">
          PROFESSIONALLY CURATED PODCASTS ON LIFE, & GENERAL HEALTH
        </p>
        <CustomButton
          name={"intro to kool health"}
          css={"px-4 py-3 text-sm md:text-lg mt-10 mb-16"}
          href={"#intro"}
        />
        <PodcastPlatform css="w-3/5 2xl:w-2/5" />
      </div>
    </div>
  );
};

export default SliderHome;
