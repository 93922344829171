import React from "react";
import { useLocation } from "react-router-dom";
import { filterActions } from "../../store/filter/filter-slice";
import { useDispatch } from "react-redux";

const ChangePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    // Display your message or perform any other actions on page change
    dispatch(filterActions.clearAllData());
  }, [location]);

  return null; // or render a message component here
};

export default ChangePage;
