import React, { useEffect, useState } from "react";
import GridIcon from "../assets/icons/grid.svg";
import ListIcon from "../assets/icons/list_bullet.svg";
import InputCustom from "../components/form/InputCustom";
import SelectCustom from "../components/form/SelectCustom";
// import { Select } from '@chakra-ui/react'
import categoriesService from "../services/categories";
import { useSelector } from "react-redux";

const Filter = ({ gridDisplay, setGridDisplay }) => {
  const [categories, setCategories] = useState([]);

  const topicSlice = useSelector((state) => state.filter.topic);
  const durationSlice = useSelector((state) => state.filter.duration);
  const dateToSlice = useSelector((state) => state.filter.to);
  const dateFromSlice = useSelector((state) => state.filter.from);

  useEffect(() => {
    categoriesService
      .getAll()
      .then((res) => setCategories(res.categories))
      .catch((err) => console.log(err));
  }, []);

  const dataDuration = [
    { _id: 0, title: "under 30 min" },
    { _id: 1, title: "between 30 min - one hour" },
    { _id: 2, title: "over one hour" },
  ];

  return (
    <div className="bg-white shadow-lg items-center rounded-xl md:mt-6 md:px-6 py-4 flex flex-col md:flex-row">
      <h1 className="md:hidden text-left w-full font-semibold text-xl mb-6 uppercase">
        filter
      </h1>
      <div className="md:grid grid-cols-12 gap-x-8 w-full">
        <div className="flex flex-col col-span-3">
          <InputCustom title={"from"} item={dateFromSlice} />
        </div>
        <div className="mt-6 md:mt-0 flex flex-col col-span-3">
          <InputCustom title={"to"} item={dateToSlice} />
        </div>
        <div className="mt-6 md:mt-0 flex flex-col col-span-3">
          <SelectCustom
            title={"duration"}
            data={dataDuration}
            item={topicSlice}
          />
        </div>
        <div className="mt-6 md:mt-0 flex flex-col col-span-3">
          <SelectCustom
            title={"topics"}
            data={categories}
            item={durationSlice}
          />
        </div>
      </div>
      {
        <div className="flex items-center justify-end ml-8">
          <div
            className={`${
              !gridDisplay
                ? "bg-primary-200 rounded-lg"
                : "bg-primary-50 bg-opacity-50 rounded-lg"
            } p-2 mr-4`}
          >
            <img
              src={ListIcon}
              alt=""
              onClick={() => setGridDisplay(false)}
              className="cursor-pointer w-[30px]"
            />
          </div>
          <div
            className={`${
              gridDisplay
                ? "bg-primary-200 rounded-lg"
                : "bg-primary-50 bg-opacity-50 rounded-lg"
            } p-2`}
          >
            <img
              src={GridIcon}
              alt=""
              onClick={() => setGridDisplay(true)}
              className="cursor-pointer w-[30px]"
            />
          </div>
        </div>
      }
    </div>
  );
};

export default Filter;
