import axios from 'axios';
import { json } from 'react-router-dom';

const url = process.env.REACT_APP_API_URL


const getAll = (limit = 100, search = '', startDate = '', endDate = '', duration, topic, status='') => {
    // const querySearch = search ? `&search=${search}` : ''
    // const queryDuration = duration ? `&duration=${duration}` : ''
    // const queryTopic = topic ? `&category=${topic}` : ''
    // const queryDateStart = startDate ? `&startDate=${startDate}` : ''
    // const queryDateEnd = endDate ? `&endDate=${endDate}` : ''
    // const queryStatus = status !== '' ? `&isPublished=${status}` : ''

    return axios
        .get(`${url}/comments`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const addCommentArticle = (id, token, content) => {
    return axios
        .post(`${url}/articles/comment/${id}`,
            { content: content },
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
            },
        })
        .then((response) => response.data)
        .catch((error) => json(error));
}

const addCommentEpisode = (id, token, content) => {
    return axios
        .post(`${url}/episodes/comment/${id}`,
            { content: content },
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
            },
        })
        .then((response) => response.data)
        .catch((error) => json(error));
}

const deleteCommentEpisode = (id, token,  commentId) => {
    return axios
        .delete(`${url}/episodes/${id}/comment/${commentId}`, 
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response) => response.data)
        .catch((error) => json(error));
}

const deleteCommentArticle = (id, token,  commentId) => {
    return axios
        .delete(`${url}/articles/${id}/comment/${commentId}`, 
            {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            }
        )
        .then((response) => response.data)
        .catch((error) => json(error));
}

export default {
    getAll,
    addCommentArticle,
    addCommentEpisode,
    deleteCommentArticle,
    deleteCommentEpisode
};
