import React from "react";

const EmptySection = ({ title }) => {
  return (
    <div className="mt-10 !mb-10">
      <h1 className="text-2xl font-semibold capitalize text-center md:text-left">
        {title}
      </h1>
      <div className=" w-full flex flex-col items-center opacity-50 border-t mt-4">
        <img
          src={require("../../assets/img/empty_data.png")}
          alt=""
          className="w-[200px]"
        />
        <h3 className="text-center font-medium">No Data</h3>
      </div>
    </div>
  );
};

export default EmptySection;
