import React, { useEffect, useRef, useState } from "react";
import NavBar from "../layouts/NavBar";
import Footer from "../layouts/Footer";
import Subscribe from "../components/ui/Subscribe";
import PodcastCard from "../components/ui/PodcastCard";
import { useDispatch, useSelector } from "react-redux";
import StickyBox from "react-sticky-box";
import PodcastPlatform from "../components/ui/PodcastPlatform";
import CommentBox from "../components/ui/CommentBox";
import IconLove from "../assets/icons/love.svg";
import IconLoveActif from "../assets/icons/love_actif.svg";
import IconDownload from "../assets/icons/download.svg";
import IconCheck from "../assets/icons/check.svg";
import IconShare from "../assets/icons/share.svg";
import PopUpShare from "../layouts/PopUpShare";
import episodeService from "../services/episode";
import { episodeActions } from "../store/episode/episode-slice";
import { Link, useParams } from "react-router-dom";
import { audioActions } from "../store/episode/audio-slice";
import download from "downloadjs";
import Media from "../layouts/Media";
import AddCommentEpisode from "../components/form/AddCommentEpisode ";
import { forwardRef } from "@chakra-ui/react";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const PodcastDetail = (props) => {
  const admin = props.admin ?? false;
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();
  const audioRef = useRef();
  const audio = useSelector((state) => state.audio.audio);
  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");

    dispatch(audioActions.replacePercentage(+percent));
    dispatch(audioActions.replaceCurrentTime(`${hours}:${minutes}:${seconds}`));
  };

  useEffect(() => {
    dispatch(audioActions.clearData());
  }, []);

  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    episodeService
      .get(id)
      .then((res) => {
        dispatch(episodeActions.replaceData(res.episode));
        dispatch(episodeActions.dataLoading());
        //! setCommentsCount(res.article.comments.length)
        dispatch(audioActions.clearData());
        dispatch(audioActions.replaceAudio(res.episode.audio.url));
        dispatch(
          audioActions.replaceTitle(
            "#" + res.episode.episodeNumber + " - " + res.episode.title
          )
        );
        dispatch(audioActions.replaceTopic(res.episode.category.title));
        dispatch(audioActions.replaceImage(res.episode.image.url));
        dispatch(audioActions.replaceDuration(res.episode.duration));
        dispatch(audioActions.replaceIdEp(id));
      })
      .catch((err) => {
        console.log(err);
      });

    episodeService
      .checkEpisodeFavorite(id, localStorage.getItem("token"))
      .then((res) => {
        // setLove(res.isFavorited)
        dispatch(audioActions.replaceLove(res.isFavorited));
      })
      .catch((err) => console.log(err));
  }, [dispatch, id]);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const user = useSelector((state) => state.auth.user);

  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  if (!statusEpisode) return null;

  return (
    <div className=" min-h-screen">
      {!admin && (
        <nav>
          <NavBar />
        </nav>
      )}

      <main
        className={`${!admin ? "responsive pt-24 md:pt-28 pb-20" : "pt-8"}`}
      >
        <Header />

        <div className="grid grid-cols-12">
          <div className={`col-span-12`}>
            <Titles />

            {/* <Detail /> */}
            <Explication />

            <PodcastPlatform css="w-full xl:w-[90%] 2xl:w-2/3 mt-10" />

            <Discuss />

            {!user && (
              <>
                <hr className="border-1" />
                <Subscribe />
              </>
            )}
            <hr className="border-1 mt-10 mb-10" />
          </div>

          {/* <div
            className={`${
              !admin ? "col-span-3" : "col-span-4"
            } pl-8 hidden md:block`}
          >
            <StickyBox offsetTop={40} offsetBottom={20} className="mt-6">
              {
                audio && <Media ref={ref} />
                && <MediaDetail admin={admin} ref={audioRef}
                    duration={props.duration} love={loveStore} />
              }
              <Actions handle={togglePopup} admin={admin} url={`${process.env.REACT_APP_FILES_URL}/${episode.audio.url}`}/>
            </StickyBox>
          </div> */}
        </div>

        <h2 className="font-bold text-xl capitalize mt-10 mb-4 text-center md:text-left">
          Other content you may like:
        </h2>
        <Other ref={audioRef} />

        {!admin && (
          <div>
            <h2 className="font-bold text-xl capitalize mt-10 mb-4">
              comments ({statusEpisode ? episode.comments.length : 0}):
            </h2>
            {user && <AddCommentEpisode />}
            <Comment />
          </div>
        )}
      </main>

      {!admin && (
        <footer>
          <Footer />
        </footer>
      )}

      <PopUpShare togglePopup={togglePopup} showPopup={showPopup} />

      {/* {audio && ( */}
      <audio
        ref={audioRef}
        onTimeUpdate={getCurrDuration}
        src={process.env.REACT_APP_FILES_URL + "/" + audio}
      ></audio>
      {/* )} */}
      {/* media player  */}
      {true && audio && <Media ref={audioRef} />}
    </div>
  );
};

export default PodcastDetail;

const Header = () => {
  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  if (!statusEpisode) return null;

  return (
    <div className="">
      <div className="relative">
        <img
          src={process.env.REACT_APP_FILES_URL + "/" + episode.image.url}
          alt=""
          className="w-full rounded-lg h-[300px] object-cover"
        />
        {/* <div className='absolute left-10 -bottom-4'>
                    <img src={ require('../assets/img/avatar-1.jpeg')} alt="" className='rounded-full w-[80px]'/>
                </div> */}
      </div>
    </div>
  );
};

const Titles = () => {
  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  if (!statusEpisode) return null;

  const date = new Date(episode.createdAt);
  const formattedDate = date
    .toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })
    .replace(",", "");

  return (
    <div>
      <h1 className="text-lg md:text-3xl text-center md:text-left font-bold mt-10">
        #{episode.episodeNumber} - {episode.title}.
      </h1>
      <Link to={`../topics/${episode.category._id}`} className="!w-fit">
        <p className="text-center md:text-left text-sm md:text-xl capitalize font-semibold hover:text-primary-200 w-fit mx-auto md:mx-0">
          {episode.category.title}
        </p>
      </Link>
      <p className="text-center md:text-left text-xs">{formattedDate}</p>
    </div>
  );
};

const Detail = () => {
  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  return (
    <div className="mt-10">
      <h2 className="font-bold text-xl mb-2 text-center md:text-left">
        Description:
      </h2>
      {statusEpisode && (
        <div
          className="text-sm text-justify"
          dangerouslySetInnerHTML={{ __html: episode.description }}
        />
      )}
    </div>
  );
};

const Discuss = () => {
  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  return (
    <div className="mt-10 mb-5">
      <h2 className="mb-3 font-bold text-xl text-center md:text-left">
        We Discuss:
      </h2>
      <ul>
        {statusEpisode &&
          episode.notes.map((item) => (
            <li
              className="flex items-center py-2 justify-between md:justify-normal"
              key={item._id}
            >
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] rounded-full border-2 mr-4 border-primary-200"></div>
                <p className="text-xs md:text-sm mr-2">{item.note}</p>
              </div>
              <p className="text-xs md:text-sm font-medium">[{item.time}]</p>
            </li>
          ))}
      </ul>
      {episode.notes.length === 0 && <p>There is no notes.</p>}
    </div>
  );
};

const Explication = () => {
  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  return (
    <div>
      <h2 className="mt-10 font-bold text-center md:text-left text-xl">
        Description:
      </h2>
      {statusEpisode && (
        <div
          className="text-sm text-justify mt-4"
          dangerouslySetInnerHTML={{ __html: episode.explication }}
        />
      )}
    </div>
  );
};

const Other = forwardRef((props, ref) => {
  const [similair, setSimilair] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    episodeService
      .getSimilar(id)
      .then((res) => setSimilair(res.similarEpisodes));
  }, []);

  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-6 mb-10`}>
      {similair &&
        similair.length > 0 &&
        similair.map((item) => (
          <div className="" key={item._id}>
            <PodcastCard
              key={item._id}
              item={item}
              ref={ref}
              gridDisplay={true}
            />
          </div>
        ))}
    </div>
  );
});

const Comment = () => {
  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);

  return (
    <div>
      {statusEpisode &&
        episode.comments.map((item) => {
          return <CommentBox key={item._id} item={item} />;
        })}
    </div>
  );
};

const Actions = ({ handle, admin = false, url }) => {
  const [love, setLove] = useState(false);
  const { id } = useParams();
  const [isDownlaod, setDownload] = useState(false);

  const episode = useSelector((state) => state.episode.item);
  const statusEpisode = useSelector((state) => state.episode.status);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const downloadAudio = async (title = "audio", url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const nameFile = statusEpisode
          ? `#${episode.episodeNumber} - ${episode.title}`
          : "audio";
        download(blob, nameFile + ".mp3", "audio/mp3");
        setDownload(true);
      });
  };

  useEffect(() => {
    episodeService
      .checkEpisodeFavorite(id, localStorage.getItem("token"))
      .then((res) => {
        setLove(res.isFavorited);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleFavorite = () => {
    if (!localStorage.getItem("token")) {
      onOpen();
      return;
    }
    if (love)
      episodeService
        .deleteEpisodeFavorite(id, localStorage.getItem("token"))
        .then((res) => {
          setLove(false);
        })
        .catch((err) => console.log(err));
    else
      episodeService
        .addEpisodeFavorite(id, localStorage.getItem("token"))
        .then((res) => {
          setLove(true);
        })
        .catch((err) => console.log(err));
  };

  return (
    <div
      className={`${
        !admin ? "bg-gray-800" : "bg-gray-700"
      } rounded-lg mt-4 px-4 py-4 grid grid-cols-3 gap-x-4`}
    >
      <button
        className="border p-4 rounded-lg flex items-center justify-center"
        onClick={handleFavorite}
      >
        <img
          src={love ? IconLoveActif : IconLove}
          alt=""
          className="w-[20px]"
        />
      </button>
      <button
        className={`border p-4 rounded-lg flex items-center justify-center ${
          isDownlaod && "cursor-default"
        }`}
        onClick={() => downloadAudio(url)}
      >
        {!isDownlaod ? (
          <img src={IconDownload} alt="" className="w-[20px]" />
        ) : (
          <img src={IconCheck} alt="" className="w-[20px]" />
        )}
      </button>
      <button
        className="border p-4 rounded-lg flex items-center justify-center"
        onClick={handle}
      >
        <img src={IconShare} alt="" className="w-[20px]" />
      </button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          bgColor={"gray.700"}
          overflow={"hidden"}
          rounded={"10"}
          width={"100%"}
        >
          <ModalHeader>Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1 className="text-lg font-semibold">You are not login</h1>
            <p className="font-medium">
              login using your account and you can add to your favorite list
            </p>
            <div className="flex items-center justify-between">
              <button
                onClick={onClose}
                className="mt-6 bg-gray-800 p-3 rounded-lg w-[45%]"
              >
                Cancel
              </button>
              <Link to={"/login"} className="w-[45%]">
                <button className="mt-6 bg-gray-800 p-3 rounded-lg w-full">
                  Confirm
                </button>
              </Link>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
