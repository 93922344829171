import React, { forwardRef, useRef, useState } from "react";
import Card from "./Card";
import IconPlay from "../../assets/icons/play.svg";
import IconPause from "../../assets/icons/pause.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import episodeService from "../../services/episode";
import { Link } from "react-router-dom";
import { audioActions } from "../../store/episode/audio-slice";
import { useDisclosure } from "@chakra-ui/react";
import PlayIcon from "../../assets/icons/play-home.svg";
import PauseIcon from "../../assets/icons/pause.svg";
import PodcastCard from "./PodcastCard";
import Media from "../../layouts/Media";

const EpisodeSection = forwardRef((props, ref) => {
  const [trending, setTrending] = useState([]);

  useEffect(() => {
    episodeService.getMostPlayed(4).then((res) => setTrending(res.episodes));
  }, []);

  return (
    <>
      <div className="section">
        <h1 className="title-section">most downloaded episodes</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-4 md:gap-y-8 mt-10">
          {trending.length > 0 &&
            trending.map((item) => (
              <PodcastCard
                key={item._id}
                item={item}
                ref={ref}
                gridDisplay={true}
              />
              // <Card
              //   bgImg={process.env.REACT_APP_FILES_URL + "/" + episode.image.url}
              //   css={"h-64 h-[310px] md:h-[260px] w-full flex items-center"}
              //   pos="bottom-0"
              //   key={episode._id}
              // >
              //   <EpisodeItem
              //     id={episode._id}
              //     ref={ref}
              //     title={`#${episode.episodeNumber} - ${episode.title}`}
              //     topic={episode.category ? episode.category.title : ""}
              //     topicId={episode.category._id}
              //     duration={episode.duration}
              //     idAudio={props.audio}
              //   />
              // </Card>
            ))}
        </div>
      </div>
    </>
  );
});

export default EpisodeSection;

// const EpisodeItem = forwardRef((props, ref) => {
//   return (
//     <div className="">
//       <div className="absolute left-0 top-0 bottom-0 right-0 bg-gradient-to-t from-primary-200  to-transparent z-20"></div>
//       <div className="flex items-center z-30 relative">
//         <Actions id={props.id} ref={ref} title={`${props.title}`} />
//         <div className="w-[80%] md:w-full">
//           <Link to={`./podcasts/archives/${props.id}`}>
//             <h3 className="hover:underline font-bold capitalize truncate w-[220px] lg:w-[400px] text-md md:text-xl mb-1 text-gray-200">
//               {props.title}
//             </h3>
//           </Link>
//           <Link to={`./topics/${props.topicId}`}>
//             <p className="capitalize text-xs md:text-sm text-gray-300">
//               {props.topic}
//             </p>
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// });

// const Actions = forwardRef((props, ref) => {
//   const [love, setLove] = useState(false);

//   const id = props.id;
//   const dispatch = useDispatch();

//   const isPlaying = useSelector((state) => state.audio.isPlaying);
//   const soundOn = useSelector((state) => state.audio.soundOn);
//   const audioStore = useSelector((state) => state.audio.audio);
//   const titleStore = useSelector((state) => state.audio.title);
//   const loveStore = useSelector((state) => state.audio.love);

//   const { isOpen, onOpen, onClose } = useDisclosure();

//   const play = async () => {
//     const audio = ref.current;
//     audio.volume = soundOn;

//     if (props.title === titleStore) {
//       if (!isPlaying) {
//         dispatch(audioActions.replaceIsPlaying(true));
//         audio.play();
//       }
//       if (isPlaying) {
//         dispatch(audioActions.replaceIsPlaying(false));
//         audio.pause();
//       }
//     } else {
//       audio.pause();
//       dispatch(audioActions.clearData());
//       episodeService.get(id).then(async (res) => {
//         console.log(res.episode.duration);
//         dispatch(audioActions.replaceAudio(res.episode.audio.url));
//         dispatch(audioActions.replaceTitle(props.title));
//         dispatch(audioActions.replaceTopic(res.episode.category.title));
//         dispatch(audioActions.replaceDuration(res.episode.duration));
//         dispatch(audioActions.replaceIsPlaying(true));
//         dispatch(audioActions.replaceIdEp(id));
//         dispatch(audioActions.replaceLove(love));
//         dispatch(audioActions.replaceImage(res.episode.image.url));
//         audio.currentTime = 0;
//         await audio.pause();
//         await audio.play().catch((error) => console.log(error));
//       });
//     }
//   };

//   useEffect(() => {
//     if (!localStorage.getItem("token")) return;
//     episodeService
//       .checkEpisodeFavorite(id, localStorage.getItem("token"))
//       .then((res) => {
//         setLove(res.isFavorited);
//       })
//       .then((err) => console.log(err));
//   }, []);

//   return (
//     <div className="flex items-center col-span-6 md:col-span-2 justify-end">
//       <div
//         className="cursor-pointer flex items-center justify-center border border-gray-300 rounded-full mr-4"
//         onClick={() => play()}
//       >
//         <img
//           src={isPlaying && titleStore === props.title ? PauseIcon : PlayIcon}
//           alt=""
//           className="w-[20px] md:w-[30px] m-2"
//         />
//       </div>
//     </div>
//   );
// });
