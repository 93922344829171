import React, { forwardRef, useState } from "react";
import IconPause from "../assets/icons/pause.svg";
import IconPlay from "../assets/icons/play-home.svg";
import IconNext from "../assets/icons/next.svg";
import IconPrev from "../assets/icons/previous.svg";
import IconRefresh from "../assets/icons/refresh.svg";
import IconLove from "../assets/icons/love-white.svg";
import IconLoveActif from "../assets/icons/love_actif.svg";
import IconSound from "../assets/icons/sound.svg";
import IconUnsound from "../assets/icons/unsound.svg";
import IconAdd from "../assets/icons/add-circle.svg";
import IconClose from "../assets/icons/close-circle.svg";
import IconMinimize from "../assets/icons/minimize.svg";
import episodeService from "../services/episode";
import { useDispatch, useSelector } from "react-redux";
import { audioActions } from "../store/episode/audio-slice";
import Slider from "../components/form/Slider";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const Media = forwardRef((props, ref) => {
  const [minimize, setMinimize] = useState(false);
  // const [love, setLove] = useState(false);
  const [replay, setReplay] = useState(false);
  // const [play, setPlay] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPlaying = useSelector((state) => state.audio.isPlaying);
  const currentTime = useSelector((state) => state.audio.currentTime);
  const duration = useSelector((state) => state.audio.duration);
  const title = useSelector((state) => state.audio.title);
  const topic = useSelector((state) => state.audio.topic);
  const soundOn = useSelector((state) => state.audio.soundOn);
  const percentage = useSelector((state) => state.audio.percentage);
  const idEp = useSelector((state) => state.audio.idEp);
  const love = useSelector((state) => state.audio.love);
  const imageStore = useSelector((state) => state.audio.image);
  const next = useSelector((state) => state.audio.next);
  const prev = useSelector((state) => state.audio.prev);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const play = () => {
    const audio = ref.current;
    audio.volume = soundOn;

    if (!isPlaying) {
      dispatch(audioActions.replaceIsPlaying(true));
      audio.play();
    }
    if (isPlaying) {
      dispatch(audioActions.replaceIsPlaying(false));
      audio.pause();
    }
  };

  const toggleSound = () => {
    const audio = ref.current;
    if (soundOn === 1) {
      dispatch(audioActions.replaceSoundOn(0));
      audio.volume = 0;
    }
    if (soundOn === 0) {
      dispatch(audioActions.replaceSoundOn(1));
      audio.volume = 1;
    }
  };

  const onChange = (e) => {
    const audio = ref.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    dispatch(audioActions.replacePercentage(e.target.value));
    dispatch(audioActions.replaceCurrentTime(currentTime));
  };

  const handleMinimize = () => {
    setMinimize(!minimize);
  };

  const handleClose = () => {
    dispatch(audioActions.replaceIsPlaying(false));
    const audio = ref.current;
    audio.pause();
  };

  const user = useSelector((state) => state.auth.user);
  const handleFavorite = () => {
    if (!user) {
      onOpen();
      return;
    }
    if (love)
      episodeService
        .deleteEpisodeFavorite(idEp, user.token)
        .then((res) => {
          dispatch(audioActions.replaceLove(false));
        })
        .catch((err) => console.log(err));
    else
      episodeService
        .addEpisodeFavorite(idEp, user.token)
        .then((res) => {
          dispatch(audioActions.replaceLove(true));
        })
        .catch((err) => console.log(err));
  };

  const pathUrl = window.location.pathname;

  const handleGoTo = (ep) => {
    const audio = ref.current;
    audio.volume = soundOn;

    episodeService.get(ep._id).then((res) => {
      dispatch(audioActions.replaceAudio(res.episode.audio.url));
      dispatch(
        audioActions.replaceTitle(
          "#" + res.episode.episodeNumber + " - " + res.episode.title
        )
      );
      dispatch(audioActions.replaceTopic(res.episode.category.title));
      dispatch(audioActions.replaceDuration(res.episode.duration));
      dispatch(audioActions.replaceIsPlaying(false));
      dispatch(audioActions.replaceSoundOn(1));
      dispatch(audioActions.replaceIdEp(ep._id));
      // dispatch(audioActions.replaceLove(love))

      episodeService.getNeighbours(res.episode.episodeNumber).then((other) => {
        dispatch(audioActions.replaceNext(other.previousEpisode));
        dispatch(audioActions.replacePrev(other.nextEpisode));
      });

      audio.currentTime = 0;
      audio.pause();

      navigate(`/podcasts/archives/${ep._id}`);
    });
  };

  if (props.type === "phone")
    return (
      <div
        className={`${
          !props.admin ? "bg-gray-800" : "bg-gray-700"
        } w-full z-10 py-3 px-4 rounded-lg md:hidden`}
      >
        <h1 className="font-semibold text-lg text-center mb-4">Now playing</h1>

        <img
          src={imageStore}
          alt=""
          className="w-full h-[100px] object-cover rounded-lg"
        />

        <div className="flex flex-col items-center  mt-2">
          <h1 className="font-bold text-sm text-center">{title}</h1>
          <p className="font-light text-xs hover:text-primary-200">{topic}</p>
        </div>

        <div className="mt-6">
          <div className="w-full">
            <Slider percentage={percentage} onChange={onChange} />
          </div>
          <div className="flex items-center justify-between mt-2">
            <p className="text-xs font-semibold">{currentTime}</p>
            <p className="text-xs font-semibold">{duration}</p>
          </div>
        </div>

        <div className="flex items-center justify-center mt-4 mb-2">
          <button
            onClick={() => next && handleGoTo(next)}
            className={`${!next && "opacity-30"}`}
          >
            <img src={IconPrev} alt="" className="w-[18px]" />
          </button>
          <button
            className="bg-primary-200 rounded-full p-2 mx-4"
            onClick={() => play()}
          >
            <img
              src={isPlaying ? IconPause : IconPlay}
              alt=""
              className="w-[20px]"
            />
          </button>
          <button
            onClick={() => prev && handleGoTo(prev)}
            className={`${!prev && "opacity-30"}`}
          >
            <img src={IconNext} alt="" className="w-[18px]" />
          </button>
        </div>
      </div>
    );

  return (
    <div className="w-full">
      {/* {!/[/]archives[/][a-zA-Z0-9]+$/g.test(pathUrl) ? ( */}
      {true ? (
        <div className="hidden md:block fixed bottom-4 z-40 w-full">
          <div className="responsive">
            <div
              className={`backdrop-blur-lg backdrop-filter backdrop-brightness-[.3] border border-primary-200 border-opacity-50  ${
                minimize ? "w-fit mx-auto" : "w-full"
              } z-10 grid grid-cols-12 py-3 px-8 rounded-lg relative group`}
            >
              {!minimize && (
                <>
                  <div className="col-span-6 flex items-center justify-between">
                    <div className="flex items-center w-2/3">
                      <div className="rounded-full bg-gray-300 w-[55px] h-[55px] flex justify-center items-center">
                        <img
                          src={
                            process.env.REACT_APP_FILES_URL + "/" + imageStore
                          }
                          alt=""
                          className="w-[45px] h-[45px] object-cover rounded-full"
                        />
                      </div>
                      <div className="ml-4">
                        <Link to={`/podcasts/archives/${idEp}`}>
                          <h1 className="hover:underline font-bold text-sm text-white  truncate w-[240px]">
                            {title}
                          </h1>
                        </Link>
                        <p className="font-light text-xs text-white">{topic}</p>
                      </div>
                    </div>
                    <div className="flex items-center  w-1/3 justify-center">
                      {/* <button
                        onClick={() => next && handleGoTo(next)}
                        className={`${!next && "opacity-30"}`}
                      >
                        <img src={IconPrev} alt="" className="w-[18px]" />
                      </button> */}
                      <button
                        className="bg-primary-200 rounded-full p-2 mx-4"
                        onClick={() => play()}
                      >
                        <img
                          src={isPlaying ? IconPause : IconPlay}
                          alt=""
                          className="w-[20px]"
                        />
                      </button>
                      {/* <button
                        onClick={() => prev && handleGoTo(prev)}
                        className={`${!prev && "opacity-30"}`}
                      >
                        <img src={IconNext} alt="" className="w-[18px]" />
                      </button> */}
                    </div>
                  </div>

                  <div className="hidden items-center justify-center">
                    <button onClick={() => setReplay(!replay)}>
                      {replay ? (
                        <img src={IconRefresh} alt="" className="w-[20px]" />
                      ) : (
                        <p className="rounded-lg border border-white font-bold text-xs px-2 py-1">
                          1
                        </p>
                      )}
                    </button>
                  </div>

                  <div className="flex items-center col-span-5 px-4">
                    <p className="text-xs font-semibold  text-white">
                      {currentTime}
                    </p>
                    {/* <div className='w-full h-1 rounded-full mx-4 bg-primary-200'> */}
                    <div className="w-full h-10 flex items-center px-2">
                      <Slider percentage={percentage} onChange={onChange} />
                    </div>
                    <p className="text-xs font-semibold text-white">
                      {duration}
                    </p>
                  </div>

                  <div className="col-span-1 flex items-center justify-between ml-5">
                    <button onClick={handleFavorite}>
                      <img
                        src={love ? IconLoveActif : IconLove}
                        alt=""
                        className="w-[20px]"
                      />
                    </button>
                    <button onClick={() => toggleSound()}>
                      <img
                        src={soundOn === 1 ? IconSound : IconUnsound}
                        alt=""
                        className="w-[20px]"
                      />
                    </button>
                  </div>
                </>
              )}

              {minimize && (
                <div className="col-span-12 flex items-center text-white">
                  <h1 className="text-xs font-semibold mr-4">{title}</h1>
                  <div className="text-xs flex items-center">
                    <p>{currentTime}</p>
                    <p className="mx-2"> / </p>
                    <p>{duration}</p>
                  </div>
                </div>
              )}

              <div className="hidden group-hover:flex absolute right-5 -top-6 items-center justify-center w-[50px] bg-[#2f4266] px-1 py-1 rounded-lg border border-gray-500">
                <button>
                  <img
                    src={minimize ? IconAdd : IconMinimize}
                    alt=""
                    className="w-5"
                    onClick={handleMinimize}
                  />
                </button>
                {/* <button>
                  <img
                    src={IconClose}
                    alt=""
                    className="w-5"
                    onClick={handleClose}
                  />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`${
            !props.admin ? "bg-gray-800" : "bg-gray-700"
          } w-full z-10 py-3 px-4 rounded-lg`}
        >
          <h1 className="font-semibold text-lg text-center mb-4">
            Now playing
          </h1>

          <img
            src={process.env.REACT_APP_FILES_URL + "/" + imageStore}
            alt=""
            className="w-full h-[100px] object-cover rounded-lg"
          />

          <div className="flex flex-col items-center  mt-2">
            <h1 className="font-bold text-sm text-center">{title}</h1>
            <p className="font-light text-xs hover:text-primary-200">{topic}</p>
          </div>

          <div className="mt-6">
            <div className="w-full">
              <Slider percentage={percentage} onChange={onChange} />
            </div>
            <div className="flex items-center justify-between mt-2">
              <p className="text-xs font-semibold">{currentTime}</p>
              <p className="text-xs font-semibold">{duration}</p>
            </div>
          </div>

          <div className="flex items-center justify-center mt-4 mb-2">
            <button
              onClick={() => next && handleGoTo(next)}
              className={`${!next && "opacity-30"}`}
            >
              <img src={IconPrev} alt="" className="w-[18px]" />
            </button>
            <button
              className="bg-primary-200 rounded-full p-2 mx-4"
              onClick={() => play()}
            >
              <img
                src={isPlaying ? IconPause : IconPlay}
                alt=""
                className="w-[20px]"
              />
            </button>
            <button
              onClick={() => prev && handleGoTo(prev)}
              className={`${!prev && "opacity-30"}`}
            >
              <img src={IconNext} alt="" className="w-[18px]" />
            </button>
          </div>
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent overflow={"hidden"} rounded={"10"} width={"100%"}>
          <ModalHeader>Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1 className="text-lg font-semibold">You are not login</h1>
            <p className="font-medium">
              login using your account and you can add to your favorite list
            </p>
            <div className="flex items-center justify-between">
              <button
                onClick={onClose}
                className="mt-6 border border-primary-50 text-primary-50 p-3 rounded-lg w-[45%]"
              >
                Cancel
              </button>
              <Link to={"/login"} className="w-[45%]">
                <button className="mt-6 bg-primary-50 text-white p-3 rounded-lg w-full">
                  Confirm
                </button>
              </Link>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
});

export default Media;
