import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const register = (name, email, username, password) =>
  axios
    .post(`${url}/auth/register`, {
      name: name,
      email: email,
      password: password,
      username: username,
    })
    .then((response) => response.data)
    .catch((error) => error.response);

const login = (username, password) =>
  axios
    .post(`${url}/auth/login`, {
      password: password,
      username: username,
    })
    .then((response) => response)
    .catch((error) => error);

const authService = {
  register,
  login,
};
export default authService;
