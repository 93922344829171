import {
  Route,
  Routes,
  BrowserRouter as Router,
  redirect,
  Navigate,
  useLocation,
} from "react-router-dom";
import React, {
  Suspense,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import Home from "./pages/Home";
// import Podcast from "./pages/Podcast";
// import Archive from "./pages/Archive";

// import Articles from "./pages/Articles";
// import ArticleDetail from "./pages/ArticleDetail";

import { useDispatch, useSelector } from "react-redux";
import { audioActions } from "./store/episode/audio-slice";
import Media from "./layouts/Media";
import Stats from "./admin/pages/Stats";
import EditSettings from "./admin/components/form/EditSettings";
import AdminRoute from "./routes/AdminRoute";
import Error from "./pages/Error";
import LoadingFallback from "./components/ui/LoadingFallback";
import { authActions } from "./store/auth/auth-slice";
import ChangePage from "./admin/pages/ChangePage";
import Login from "./components/form/Login";
import AddPodcast from "./admin/components/form/AddPodcast";

import AddGroup from "./admin/components/form/AddGroup";
import AddTopic from "./admin/components/form/AddTopic";
import AddArticle from "./admin/components/form/AddArticle";

const LazyComponentPA = React.lazy(() => import("./admin/pages/PodcastAdmin"));
const LazyComponentA = React.lazy(() => import("./admin/pages/Add"));

// import PodcastDetail from "./pages/PodcastDetail";
const LazyComponentD = React.lazy(() => import("./admin/pages/Dashboard"));
const LazyComponentPAD = React.lazy(() =>
  import("./admin/pages/PodcastAdminDetail")
);
const LazyComponentAA = React.lazy(() => import("./admin/pages/ArticleAdmin"));
// import Topics from "./pages/Topics";
// import TopicDetail from "./pages/TopicDetail";
const LazyComponentTA = React.lazy(() => import("./admin/pages/TopicAdmin"));
const LazyComponentTAD = React.lazy(() =>
  import("./admin/pages/TopicAdminDetail")
);
// import Auth from "./pages/Auth";
const LazyComponentAAD = React.lazy(() =>
  import("./admin/pages/ArticleAdminDetail")
);
// import Notes from "./pages/Notes";
const LazyComponentCA = React.lazy(() => import("./admin/pages/CommentAdmin"));
// import Profile from "./pages/Profile";
const LazyComponentUA = React.lazy(() => import("./admin/pages/UserAdmin"));
const LazyComponentGAD = React.lazy(() =>
  import("./admin/pages/GroupAdminDetail")
);
const LazyComponentBMA = React.lazy(() => import("./admin/pages/BookMeAdmin"));
const LazyComponentPodcast = React.lazy(() => import("./pages/Podcast"));
const LazyComponentArchive = React.lazy(() => import("./pages/Archive"));
const LazyComponentPodcastDetail = React.lazy(() =>
  import("./pages/PodcastDetail")
);
const LazyComponentArticles = React.lazy(() => import("./pages/Articles"));
const LazyComponentArticleDetail = React.lazy(() =>
  import("./pages/ArticleDetail")
);
const LazyComponentTopics = React.lazy(() => import("./pages/Topics"));
const LazyComponentTopicDetail = React.lazy(() =>
  import("./pages/TopicDetail")
);
const LazyComponentAuth = React.lazy(() => import("./pages/Auth"));
const LazyComponentNotes = React.lazy(() => import("./pages/Notes"));
const LazyComponentProfile = React.lazy(() => import("./pages/Profile"));
// const LazyComponent = React.lazy(() => import(""));
// const LazyComponent = React.lazy(() => import(""));
function App() {
  const audioRef = useRef(null);
  const dispatch = useDispatch();

  const audio = useSelector((state) => state.audio.audio);

  const isAuthenticated = localStorage.getItem("token") ?? true;
  const role = localStorage.getItem("role");

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");

    dispatch(audioActions.replacePercentage(+percent));
    dispatch(audioActions.replaceCurrentTime(`${hours}:${minutes}:${seconds}`));
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) dispatch(authActions.login(JSON.parse(user)));
  }, []);

  const user = useSelector((state) => state.auth.user);

  return (
    <div className="bg-[#f5f5f5]">
      {/* {audio && (
        <audio
          ref={audioRef}
          onTimeUpdate={getCurrDuration}
          src={process.env.REACT_APP_FILES_URL + "/" + audio}
        ></audio>
      )} */}

      <main className="">
        <Router>
          <ChangePage />
          <Routes>
            {/* for users  */}
            <Route exact path="/" element={<Home ref={audioRef} />} />
            <Route
              path="login"
              element={
                !user ? (
                  <Suspense fallback={<LoadingFallback />}>
                    <LazyComponentAuth />
                  </Suspense>
                ) : (
                  <Navigate to={"/"} />
                )
              }
            />
            <Route
              path="register"
              element={
                !user ? (
                  <Suspense fallback={<LoadingFallback />}>
                    <LazyComponentAuth />
                  </Suspense>
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            <Route
              path="podcasts"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentPodcast ref={audioRef} />
                </Suspense>
              }
            />
            <Route
              path="podcasts/archives"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentArchive ref={audioRef} />
                </Suspense>
              }
            />
            <Route
              path="podcasts/archives/:id"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentPodcastDetail ref={audioRef} />
                </Suspense>
              }
            />

            <Route
              path="articles"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentArticles ref={audioRef} />
                </Suspense>
              }
            />
            <Route
              path="articles/:id"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentArticleDetail ref={audioRef} />
                </Suspense>
              }
            />

            <Route
              path="topics"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentTopics ref={audioRef} />
                </Suspense>
              }
            />
            <Route
              path="topics/:id"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentTopicDetail ref={audioRef} />
                </Suspense>
              }
            />

            <Route
              path="notes"
              element={
                <Suspense fallback={<LoadingFallback />}>
                  <LazyComponentNotes ref={audioRef} />
                </Suspense>
              }
            />

            <Route
              path="profile"
              element={
                user ? (
                  <Suspense fallback={<LoadingFallback />}>
                    <LazyComponentProfile ref={audioRef} />
                  </Suspense>
                ) : (
                  <Navigate to={"/login"} />
                )
              }
            />

            <Route path="*" element={<Error ref={audioRef} />} />

            {/* for admin  */}

            <Route path="kxo7aw80p5b9ldq/admin">
              {user && user.role === "Admin" ? (
                <>
                  <Route
                    path=""
                    exact
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentD />
                      </Suspense>
                    }
                  />
                  <Route
                    path="bookme"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentBMA />
                      </Suspense>
                    }
                  />
                  <Route
                    path="podcasts"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentPA />
                      </Suspense>
                    }
                  />
                  <Route
                    path="podcasts/:id"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentPAD />
                      </Suspense>
                    }
                  />
                  <Route
                    path="podcasts/add"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddPodcast />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />
                  <Route
                    path="podcasts/:id/edit"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddPodcast />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />

                  <Route
                    path="articles"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentAA />
                      </Suspense>
                    }
                  />
                  <Route
                    path="articles/:id"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentAAD />
                      </Suspense>
                    }
                  />
                  <Route
                    path="articles/add"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddArticle />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />
                  <Route
                    path="articles/:id/edit"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddArticle />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />

                  <Route
                    path="topics"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentTA />
                      </Suspense>
                    }
                  />
                  <Route
                    path="topics/:id"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentTAD />
                      </Suspense>
                    }
                  />
                  <Route
                    path="topics/add"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddTopic />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />
                  <Route
                    path="topics/:id/edit"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddTopic />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />
                  <Route
                    path="groups/:id"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentGAD />
                      </Suspense>
                    }
                  />
                  <Route
                    path="groups/add"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddGroup />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />
                  <Route
                    path="groups/:id/edit"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddGroup />
                        </LazyComponentA>
                      </Suspense>
                    }
                  />

                  <Route
                    path="comments"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentCA />
                      </Suspense>
                    }
                  />

                  <Route
                    path="users"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentUA />
                      </Suspense>
                    }
                  />

                  {/* <Route
                    path="settings"
                    element={
                      <Suspense fallback={LoadingFallback}>
                        <LazyComponentA>
                          <AddPodcast />
                        </LazyComponentA>
                      </Suspense>
                    }
                  /> */}

                  <Route path="stats" element={<Stats />} />
                </>
              ) : (
                <Route path="" element={<Error ref={audioRef} />} />
              )}
            </Route>
          </Routes>
        </Router>
      </main>
    </div>
  );
}

export default App;
