import { createSlice } from "@reduxjs/toolkit";

const audioSlice = createSlice({
  name: "audio",
  initialState: {
    image: "",
    percentage: 0,
    isPlaying: false,
    soundOn: 1,
    duration: 0,
    currentTime: "00:00:00",
    audio: null,
    title: "",
    topic: "",
    love: false,
    next: {},
    prev: {},
    idEp: "",
  },
  reducers: {
    replaceAudio(state, action) {
      state.audio = action.payload;
    },
    replaceImage(state, action) {
      state.image = action.payload;
    },
    replaceSoundOn(state, action) {
      state.soundOn = action.payload;
    },
    replacePercentage(state, action) {
      state.percentage = action.payload;
    },
    replaceIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    replaceDuration(state, action) {
      state.duration = action.payload;
    },
    replaceCurrentTime(state, action) {
      state.currentTime = action.payload;
    },
    replaceTitle(state, action) {
      state.title = action.payload;
    },
    replaceTopic(state, action) {
      state.topic = action.payload;
    },
    replaceIdEp(state, action) {
      state.idEp = action.payload;
    },
    replaceLove(state, action) {
      state.love = action.payload;
    },
    replacePrev(state, action) {
      state.prev = action.payload;
    },
    replaceNext(state, action) {
      state.next = action.payload;
    },
    clearData(state, action) {
      state.percentage = 0;
      state.isPlaying = false;
      state.currentTime = "00:00:00";
      state.idEp = "";
      state.love = false;
    },
  },
});

export const audioActions = audioSlice.actions;

export default audioSlice;
