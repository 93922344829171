import axios from 'axios';
import { json } from 'react-router-dom';

const url = process.env.REACT_APP_API_URL

const getInfo = () => {
    return axios
        .get(`${url}/infos`)
        .then((response) => response.data)
        .catch((error) => json(error));
}

const updateInfo = (titleOverview, desecriptionOverview, desecriptionOverviewNote, desecriptionOverviewPodcast) => {
    return axios
        .patch(`${url}/infos/updateInfo`, {
            overview: {
                title: titleOverview,
                description: desecriptionOverview,
                podcastDescription: desecriptionOverviewPodcast,
                podcastSmallDescription: '',
                notesDescription: desecriptionOverviewNote,
                notesSmallDescription: '',
            }
        })
        .then((response) => response.data)
        .catch((error) => json(error));
}

const settingService = {
    getInfo,
    updateInfo
};
export default settingService
