import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'

import IconNote from '../assets/icons/note.svg'
import IconArticle from '../assets/icons/article.svg'
import IconPodcast from '../assets/icons/podcasts.svg'
import IconTopic from '../assets/icons/topic.svg'
import IconHome from '../assets/icons/home.svg'
import IconNoteSelect from '../assets/icons/NoteSelect.svg'
import IconArticleSelect from '../assets/icons/articleSelect.svg'
import IconPodcastSelect from '../assets/icons/podcastSelect.svg'
import IconTopicSelect from '../assets/icons/topicSelect.svg'
import IconHomeSelect from '../assets/icons/homeSelect.svg'

const NavBarMobile = () => {
    var pathUrl = window.location.pathname

    
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10;
            setScrolled(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`md:hidden px-8 w-full fixed z-50 left-0 bottom-0 ${scrolled ? 'bg-gray-700' : 'bg-gray-800'}`}>
            <ul className='flex justify-between items-end py-3 px-4'>
                <li className='capitalize font-medium cursor-pointer'>
                    {pathUrl.includes('topics')
                        ?<Link to={'/topics'} className='w-full'><img src={pathUrl.includes('topics') ?  IconTopicSelect : IconTopic} alt="" className='w-5'/></Link>
                        :<Link to={'/topics'} className='w-full'><img src={pathUrl.includes('topics') ?  IconTopicSelect : IconTopic} alt="" className='w-5'/></Link>
                    }
                </li>
                <li className='capitalize font-medium cursor-pointer'>
                    {pathUrl.includes('podcasts')
                        ?<Link to='/podcasts'><img src={IconPodcastSelect} alt="" className='w-6'/></Link>
                        :<Link to='/podcasts'><img src={IconPodcast} alt="" className='w-6'/></Link>
                    }
                </li>
                <li className='capitalize font-medium cursor-pointer'>
                    {!pathUrl.includes('topics') && !pathUrl.includes('podcasts') && !pathUrl.includes('articles') && !pathUrl.includes('notes')
                        ?<Link to='/'><img src={IconHomeSelect} alt="" className='w-8'/></Link>
                        :<Link to='/'><img src={IconHome} alt="" className='w-8'/></Link>
                    }
                </li>
                <li className='capitalize font-medium cursor-pointer'>
                    {pathUrl.includes('articles')
                        ?<Link to='/articles'><img src={IconArticleSelect} alt="" className='w-6'/></Link>
                        :<Link to='/articles'><img src={IconArticle} alt="" className='w-6'/></Link>
                    }
                </li>
                <li className='capitalize font-medium cursor-pointer'>
                    {pathUrl.includes('notes')
                        ?<Link to='/notes'><img src={IconNoteSelect} alt="" className='w-5'/></Link>
                        :<Link to='/notes'><img src={IconNote} alt="" className='w-5'/></Link>
                    }
                </li>
            </ul>
        </div >
    )
}

export default NavBarMobile