import React from "react";
import { useSelector } from "react-redux";

const EndorsementsSection = () => {
  const data = useSelector((state) => state.setting.item);
  const dataLoading = useSelector((state) => state.setting.status);

  if (!dataLoading) return;

  return (
    <div className="section">
      <h1 className="title-section text-primary-50">
        Kool Health Endorsements
      </h1>

      <p className="mt-6 text-center mb-10">{data.endorsement}</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-20 gap-y-6 mt-10">
        <div className="flex flex-col items-center">
          <img
            src={require("../../assets/img/_VIC8485-30.jpg")}
            alt=""
            className="rounded-full w-[180px] h-[180px] md:w-[150px] md:h-[150px] object-cover mb-6"
          />
          <p className="text-center font-bold uppercase text-xs md:text-sm">
            {data.drive.text}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={require("../../assets/img/_VIC8485-30.jpg")}
            alt=""
            className="rounded-full w-[180px] h-[180px] md:w-[150px] md:h-[150px] object-cover mb-6"
          />
          <p className="text-center font-bold uppercase text-xs md:text-sm">
            {data.drive2.text}
          </p>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={require("../../assets/img/_VIC8485-30.jpg")}
            alt=""
            className="rounded-full w-[180px] h-[180px] md:w-[150px] md:h-[150px] object-cover mb-6"
          />
          <p className="text-center font-bold uppercase text-xs md:text-sm">
            {data.drive3.text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EndorsementsSection;
