import data from "../../settings/podcast_platfrom.json";

const PodcastPlatform = ({ css = "w-[60%]" }) => {
  return null;
  return (
    <div className={`w-full md:${css}`}>
      <ul className="flex justify-center md:justify-between w-full flex-wrap">
        {data.map((item, idx) => {
          return (
            <li key={idx}>
              <a href="#" className="flex items-center mx-4 mb-4 md:mb-0">
                <img
                  src={item.icon && require(`../../assets/icons/${item.icon}`)}
                  alt=""
                  className={`w-[20px] mr-2`}
                />
                <p className="text-sm md:text-sm capitalize font-semibold">
                  {item.name}
                </p>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PodcastPlatform;
