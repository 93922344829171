import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import Badge from "./Badge";
import IconBin from "../../../assets/icons/bin.svg";
import IconFile from "../../../assets/icons/file.svg";
import IconEdit from "../../../assets/icons/edit.svg";
import IconUpload from "../../../assets/icons/upload.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FilesUploadTable = ({
  oneFile = false,
  files,
  uploadProgress,
  remove,
  update,
  imgs,
  twoImg = false,
  writer = false,
}) => {
  if (!files || files.length === 0) return null;

  function formatFileSize(sizeInBytes) {
    const units = ["bytes", "KB", "MB", "GB", "TB"];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  }

  if (oneFile)
    return (
      <TableContainer className="w-full border border-gray-700 p-4 rounded-lg">
        <Table variant="unstyled" className="w-full">
          <Thead>
            <Tr className="text-left uppercase text-xs font-semibold">
              {/* <Th>...</Th> */}
              <Th>file name</Th>
              <Th>file size</Th>
              <Th>file type</Th>
              <Th>percent upload</Th>
              <Th>upload/remove</Th>
            </Tr>
          </Thead>
          <Tbody className="text-sm">
            <Tr className="">
              {/* <Td>
                                <img src={imgs} alt="" className='w-8 h-8 rounded-full'/>
                            </Td> */}
              <Td className="py-3">
                <div className="flex items-center">
                  <div className="bg-primary-50 p-2 rounded-full">
                    <img src={IconFile} alt="" className="w-[20px]" />
                  </div>
                  <p className="ml-4 truncate w-[200px]">
                    {files.name.replace(/[.][a-zA-Z0-9]+$/, "")}
                  </p>
                </div>
              </Td>
              <Td>
                <p>{formatFileSize(files.size)}</p>
              </Td>
              <Td>
                <p>{files.type}</p>
              </Td>
              <Td className="">
                <p>{uploadProgress} %</p>
              </Td>
              <Td className="flex flex-1 mt-2 items-center justify-center">
                {uploadProgress < 100 && (
                  <button className="mx-4 ml-10 bg-primary-50 rounded-full p-2">
                    <img
                      src={IconUpload}
                      alt=""
                      className="w-[17px]"
                      onClick={(e) =>
                        update(
                          e,
                          files,
                          null,
                          twoImg // if not null
                            ? writer
                            : files.type.toString().startsWith("audio/")
                        )
                      }
                    />
                  </button>
                )}
                <button className="mx-2 bg-primary-50 rounded-full p-2">
                  <img
                    src={IconBin}
                    alt=""
                    className="w-[17px]"
                    onClick={(e) => remove(e, files.name, oneFile)}
                  />
                </button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    );
  return (
    <TableContainer className="w-full border p-4 rounded-lg">
      <Table variant="unstyled" className="w-full">
        <Thead>
          <Tr className="text-left uppercase text-xs font-semibold">
            <Th>...</Th>
            <Th>file name</Th>
            <Th>file size</Th>
            <Th>file type</Th>
            <Th>percent upload</Th>
            <Th>upload/remove</Th>
          </Tr>
        </Thead>
        <Tbody className="text-sm">
          {files.map((item, idx) => (
            <Tr className="" key={idx}>
              <Td>
                <img
                  src={Array.isArray(uploadProgress) ? imgs[idx] : imgs}
                  alt=""
                  className="w-8 h-8 rounded-full"
                />
              </Td>
              <Td className="py-3">
                <div className="flex items-center">
                  <img src={IconFile} alt="" className="w-[20px]" />
                  <p className="ml-4">
                    {item.name.replace(/[.][a-zA-Z0-9]+$/, "")}
                  </p>
                </div>
              </Td>
              <Td>
                <p>{formatFileSize(item.size)}</p>
              </Td>
              <Td>
                <p>Image</p>
              </Td>
              <Td className="">
                <p>
                  {Array.isArray(uploadProgress)
                    ? uploadProgress[idx]
                    : uploadProgress}{" "}
                  %
                </p>
              </Td>
              <Td className="flex flex-1 mt-2 items-center justify-center">
                {Array.isArray(uploadProgress)
                  ? uploadProgress[idx]
                  : uploadProgress < 100 && (
                      <button className="mx-4 ml-10">
                        <img
                          src={IconUpload}
                          alt=""
                          className="w-[17px]"
                          onClick={(e) =>
                            update(e, item, idx, Array.isArray(uploadProgress))
                          }
                        />
                      </button>
                    )}
                <button className="mx-2">
                  <img
                    src={IconBin}
                    alt=""
                    className="w-[17px]"
                    onClick={(e) =>
                      remove(e, item.name, Array.isArray(uploadProgress))
                    }
                  />
                </button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default FilesUploadTable;
