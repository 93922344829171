import axios from "axios";
import { json } from "react-router-dom";

const url = process.env.REACT_APP_API_URL;

const getAll = (search) => {
  const querySearch = search ? `&search=${search}` : "";

  return axios
    .get(`${url}/categories?${querySearch}`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const get = (id) =>
  axios
    .get(`${url}/categories/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const getGroups = (search) => {
  const querySearch = search ? `&search=${search}` : "";

  return axios
    .get(`${url}/groups?${querySearch}`)
    .then((response) => response.data)
    .catch((error) => json(error));
};

const getGroup = (id) =>
  axios
    .get(`${url}/groups/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const addCategory = (title, description, image, main = 0) =>
  axios
    .post(`${url}/categories`, {
      title: title,
      description: description,
      image: image,
      main: main,
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const addGroup = (title, description, categories) =>
  axios
    .post(`${url}/groups`, {
      title: title,
      description: description,
      categories: categories,
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const updateCategory = (
  id,
  title,
  description,
  image,
  fileImgName,
  fileImgSize
) =>
  axios
    .patch(`${url}/categories/${id}`, {
      title: title,
      description: description,
      image: {
        name: fileImgName,
        url: image,
        size: fileImgSize,
      },
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const updateGroup = (id, title, description, categories) =>
  axios
    .patch(`${url}/groups/${id}`, {
      title: title,
      description: description,
      categories: categories,
    })
    .then((response) => response.data)
    .catch((error) => json(error));

const toggleCategory = (id) =>
  axios
    .put(`${url}/categories/isPublished/${id}`)
    .then((response) => response.data)
    .catch((error) => json(error));

const deleteCategory = (id) =>
  axios
    .delete(`${url}/categories/${id}`)
    .then((response) => response)
    .catch((error) => json(error));

const deleteGroup = (id) =>
  axios
    .delete(`${url}/groups/${id}`)
    .then((response) => response)
    .catch((error) => json(error));

const categoriesService = {
  getAll,
  get,
  addCategory,
  addGroup,
  updateCategory,
  toggleCategory,
  deleteCategory,
  getGroups,
  getGroup,
  updateGroup,
  deleteGroup,
};
export default categoriesService;
