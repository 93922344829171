import React from "react";
import SideBar from "../layout/SideBar";
import StickyBox from "react-sticky-box";
import PodcastDetail from "../../pages/PodcastDetail";
import Card from "../components/ui/Card";
import TopicDetail from "../../pages/TopicDetail";
import { useEffect, useState } from "react";
import statsService from "../../services/stats";
import BarChart from "../components/chart/BarChart";
import { Bar, Pie } from "react-chartjs-2";
import PieChart from "../components/chart/PieChartTotal";
import { StatGroup } from "@chakra-ui/react";
import BarChartDetail from "../components/chart/BarChartHorizontal";
import BarChartHorizontal from "../components/chart/BarChartHorizontal";
import BarChartVertical from "../components/chart/BarChartVertical";

const Stats = () => {
  const [statArticles, setStatArticles] = useState({});
  const [statEpisodes, setStatEpisodes] = useState({});
  const [statTopics, setStatTopics] = useState({});
  const [statGroups, setStatGroups] = useState({});
  const [statUsers, setStatUsers] = useState({});
  const [statNotes, setStatNotes] = useState({});
  const [statComments, setStatComments] = useState({});

  const data = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
    height: "300px",
    width: "200px",
    options: {
      width: "300px",
      height: "300px",
      maintainAspectRatio: false,
    },
  };

  useEffect(() => {
    statsService.getStatArticles().then((res) => {
      setStatArticles(res);
    });
    statsService.getStatEpisodes().then((res) => {
      setStatEpisodes(res);
    });
    statsService.getStatCategories().then((res) => {
      setStatTopics(res);
    });
    statsService.getStatGroups().then((res) => {
      setStatGroups(res);
    });
    statsService.getStatNotes().then((res) => {
      setStatNotes(res);
    });
    statsService.getStatUser().then((res) => {
      setStatUsers(res);
    });
    statsService.getStatComments().then((res) => {
      setStatComments(res);
    });
  }, []);

  return (
    <div className="grid grid-cols-12 min-h-screen">
      <aside className="col-span-2 bg-primary-50 text-white pt-10">
        <StickyBox offsetTop={40} offsetBottom={20}>
          <SideBar />
        </StickyBox>
      </aside>
      <main className="col-span-10 px-8 pt-10 grid grid-cols-12 gap-4 mb-10">
        <Card css={"col-span-12"}>
          {/* <Pie data={data} width={"30%"} options={{ maintainAspectRatio: false }} /> */}
          <PieChart
            valArticle={statArticles}
            valEpisode={statEpisodes}
            valTopics={statTopics}
            valGroups={statGroups}
          />
        </Card>

        <Card css={"h-[300px] col-span-6"}>
          {statGroups.length > 0 && (
            <BarChartVertical
              labelNames={statGroups.map((item) => item.title)}
              val1={statGroups.map((item) => item.numberOfArticles)}
              val2={statGroups.map((item) => item.numberOfEpisodes)}
              val3={statGroups.map((item) => item.numberOfCategories)}
            />
          )}
        </Card>

        <Card css={"h-[300px] col-span-6"}>
          {statGroups.length > 0 && (
            <BarChartHorizontal
              labelNames={["articles", "episodes"]}
              val1={[statArticles.totalReadCount, statEpisodes.totalPlayCount]}
              val2={[
                statArticles.publishedArticle,
                statEpisodes.publishedEpisodes,
              ]}
              val3={[
                statArticles.notPublishedArticle,
                statEpisodes.notPublishedEpisodes,
              ]}
            />
          )}
        </Card>
      </main>
    </div>
  );
};

export default Stats;
