import React, { forwardRef, useEffect, useRef, useState } from "react";
import ClockIcon from "../../assets/icons/clock.svg";
import LoveIcon from "../../assets/icons/love.svg";
import LoveActifIcon from "../../assets/icons/love_actif.svg";
import PlayIcon from "../../assets/icons/play.svg";
import PauseIcon from "../../assets/icons/pause-black.svg";
import { Link, useNavigate } from "react-router-dom";
import episodeService from "../../services/episode";
import { useDispatch, useSelector } from "react-redux";
import { audioActions } from "../../store/episode/audio-slice";
import { useToast } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const PodcastCard = forwardRef((props, ref) => {
  const show = props.show ? false : true;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathUrl = window.location.pathname;

  const toast = useToast();

  const [msgToast, setMsgToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [titleToast, setTitleToast] = useState("");

  const titleEp = useSelector((state) => state.audio.title);
  const soundOn = useSelector((state) => state.audio.soundOn);

  const handleGoTo = (id) => {
    const audio = ref.current;
    audio.volume = soundOn;

    episodeService.get(id).then((res) => {
      if (
        titleEp !==
        "#" + res.episode.episodeNumber + " - " + res.episode.title
      ) {
        // dispatch(audioActions.clearData());

        dispatch(audioActions.replaceAudio(res.episode.audio.url));
        dispatch(
          audioActions.replaceTitle(
            "#" + res.episode.episodeNumber + " - " + res.episode.title
          )
        );
        dispatch(audioActions.replaceTopic(res.episode.category.title));
        dispatch(audioActions.replaceDuration(res.episode.duration));
        dispatch(audioActions.replaceIsPlaying(false));
        dispatch(audioActions.replaceSoundOn(1));
        dispatch(audioActions.replaceIdEp(id));
        dispatch(audioActions.replaceImage(res.episode.image.url));
        // dispatch(audioActions.replaceLove(love))

        episodeService
          .getNeighbours(res.episode.episodeNumber)
          .then((other) => {
            dispatch(audioActions.replaceNext(other.previousEpisode));
            dispatch(audioActions.replacePrev(other.nextEpisode));
          });

        audio.currentTime = 0;
        audio.pause();
      }
      // if (pathUrl.includes("topics")) navigate(`../../podcasts/archives/${id}`);
      // if (pathUrl.includes("archives")) navigate(`./${id}`);
      // else navigate(`./archives/${id}`);
      navigate(`/podcasts/archives/${id}`);
    });
  };

  return (
    <div
      className={`bg-white shadow-lg rounded-xl relative overflow-hidden flex justify-between items-center p-4 ${
        pathUrl.includes("admin") && "border border-primary-50"
      }`}
    >
      <div className="hidden md:block">
        {props.gridDisplay ? (
          <div className="w-[50px] md:w-[80px] h-[50px] md:h-[80px] bg-gray-200 rounded-full flex justify-center items-center overflow-hidden">
            <img
              src={process.env.REACT_APP_FILES_URL + "/" + props.item.image.url}
              alt=""
              className="w-[40px] md:w-[65px] h-[40px] md:h-[65px] object-cover rounded-full"
            />
          </div>
        ) : (
          <div className="w-[200px] md:w-[200px] h-full bg-gray-700 flex justify-center items-center overflow-hidden rounded-lg">
            <img
              src={process.env.REACT_APP_FILES_URL + "/" + props.item.image.url}
              alt=""
              className="w-full h-[200px] object-cover"
            />
          </div>
        )}
      </div>
      <div className="pl-4 grid grid-cols-6 w-[95%] realtive z-30">
        {props.gridDisplay ? (
          <div className="col-span-6 md:col-span-4">
            {
              <h1
                className="cursor-pointer text-sm md:text-md md:text-lg font-semibold truncate w-full md:w-[250px] hover:underline"
                onClick={() => handleGoTo(props.item._id)}
              >
                #{props.item.episodeNumber} - {props.item.title}
              </h1>
            }
            {!pathUrl.includes("topics") && (
              <Link to={`/topics/${props.item.category._id}`}>
                <p className="text-xs md:text-sm font-light w-fit">
                  {props.item.category.title}
                </p>
              </Link>
            )}
            <div className="flex md:block items-center justify-between">
              <div className="flex items-center mt-2">
                <img src={ClockIcon} alt="" className="w-[15px]" />
                <p className="text-xs ml-2 ">{props.item.duration}</p>
              </div>
              <div className="md:hidden">
                {show && (
                  <Actions
                    id={props.item._id}
                    ref={ref}
                    title={`#${props.item.episodeNumber} - ${props.item.title}`}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`col-span-6 ${
              props.gridDisplay ? "md:col-span-4" : "md:col-span-5"
            }`}
          >
            {
              <h1
                className="cursor-pointer text-sm md:text-md md:text-lg font-semibold truncate w-full hover:underline"
                onClick={() => handleGoTo(props.item._id)}
              >
                #{props.item.episodeNumber} - {props.item.title}
              </h1>
            }
            {!pathUrl.includes("topics") && (
              <Link to={`/topics/${props.item.category._id}`}>
                <p className="text-xs md:text-sm font-light w-fit">
                  {props.item.category.title}
                </p>
              </Link>
            )}

            <div className="flex md:block items-center justify-between">
              <div className="flex items-center mt-2">
                <img src={ClockIcon} alt="" className="w-[15px]" />
                <p className="text-xs ml-2 text-primary-50">
                  {props.item.duration}
                </p>
              </div>
              <div className="md:hidden">
                {show && (
                  <Actions
                    id={props.item._id}
                    ref={ref}
                    title={`#${props.item.episodeNumber} - ${props.item.title}`}
                  />
                )}
              </div>
            </div>

            <p className="text-sm mt-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: props.item.explication.slice(0, 300) + "...",
                }}
              />
            </p>
          </div>
        )}

        <div
          className={`hidden md:flex ${
            props.gridDisplay ? "md:col-span-2" : "md:col-span-1"
          } items-center justify-end`}
        >
          {show && (
            <Actions
              id={props.item._id}
              ref={ref}
              title={`#${props.item.episodeNumber} - ${props.item.title}`}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default PodcastCard;

const Actions = forwardRef((props, ref) => {
  const [love, setLove] = useState(false);

  const id = props.id;
  const dispatch = useDispatch();

  const isPlaying = useSelector((state) => state.audio.isPlaying);
  const soundOn = useSelector((state) => state.audio.soundOn);
  const audioStore = useSelector((state) => state.audio.audio);
  const titleStore = useSelector((state) => state.audio.title);
  const loveStore = useSelector((state) => state.audio.love);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const play = async () => {
    const audio = ref.current;
    audio.volume = soundOn;

    if (props.title === titleStore) {
      if (!isPlaying) {
        dispatch(audioActions.replaceIsPlaying(true));
        audio.play();
      }
      if (isPlaying) {
        dispatch(audioActions.replaceIsPlaying(false));
        audio.pause();
      }
    } else {
      audio.pause();
      dispatch(audioActions.clearData());
      episodeService.get(id).then(async (res) => {
        dispatch(audioActions.replaceAudio(res.episode.audio.url));
        dispatch(audioActions.replaceTitle(props.title));
        dispatch(audioActions.replaceTopic(res.episode.category.title));
        dispatch(audioActions.replaceDuration(res.episode.duration));
        dispatch(audioActions.replaceIsPlaying(true));
        dispatch(audioActions.replaceIdEp(id));
        dispatch(audioActions.replaceLove(love));
        dispatch(audioActions.replaceImage(res.episode.image.url));
        audio.currentTime = 0;
        await audio.pause();
        await audio.play().catch((error) => console.log(error));
      });
    }
  };

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user)
      episodeService
        .checkEpisodeFavorite(id, user.token)
        .then((res) => {
          setLove(res.isFavorited);
        })
        .catch((err) => console.log(err));
  }, [user]);

  const handleFavorite = () => {
    if (!user) {
      onOpen();
      return;
    }
    if (love)
      episodeService
        .deleteEpisodeFavorite(id, user.token)
        .then((res) => {
          setLove(false);
        })
        .catch((err) => console.log(err));
    else
      episodeService
        .addEpisodeFavorite(id, user.token)
        .then((res) => {
          setLove(true);
        })
        .catch((err) => console.log(err));
  };

  return (
    <div className="flex items-center col-span-6 md:col-span-2 justify-end">
      <div
        className="cursor-pointer flex items-center justify-center border border-gray-300 rounded-full mr-4"
        onClick={() => play()}
      >
        <img
          src={isPlaying && titleStore === props.title ? PauseIcon : PlayIcon}
          alt=""
          className="w-[10px] md:w-[20px] m-2"
        />
      </div>
      <div
        className="cursor-pointer flex items-center justify-center border border-gray-300 rounded-full"
        onClick={handleFavorite}
      >
        {love ? (
          <img
            src={LoveActifIcon}
            alt=""
            className="w-[10px] md:w-[20px] m-2"
          />
        ) : (
          <img src={LoveIcon} alt="" className="w-[10px] md:w-[20px] m-2" />
        )}
      </div>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent overflow={"hidden"} rounded={"10"}>
          <ModalHeader>Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <h1 className="text-lg font-semibold">You are not login</h1>
            <p className="font-medium">
              login using your account and you can add to your favorite list
            </p>
            <div className="flex items-center justify-between">
              <button
                onClick={onClose}
                className="mt-6 border border-primary-50 text-primary-50 p-3 rounded-lg w-[45%]"
              >
                Cancel
              </button>
              <Link to={"/login"} className="w-[45%]">
                <button className="mt-6 bg-primary-50 text-white p-3 rounded-lg w-full">
                  Confirm
                </button>
              </Link>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
});
